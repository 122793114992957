.tabs_wrapper {
    margin-top: 30px;
    .btn_wrapper {
        display: flex;
        margin-top: 15px;
        justify-content: flex-start;
        margin-bottom: 15px;
    
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 169px;
            background-color: $BASE_YELLOW_COLOR;
            border: none;
    
            span,
            svg {
                color: $BASE_WHITE_COLOR;
                font-size: 13px;
            }
        }

        .right_btns {
            margin-left: auto;
            margin-right: 1%;
            display: flex;
            gap: 10px;
          }
    }
}

.configure_tab_header_tabs {
    min-width: 680px;
    max-width: 35%;
    padding-top: 20px;
    padding-left: 20px;
    .input_search {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 169px;
        .ant-input-affix-wrapper:hover {
            border-color: transparent;
          }
        .ant-input-affix-wrapper {
            border-color: transparent;
            background-color: $MAIN_BG_COLOR;
            border-radius: 4px;
            input {
                border-radius: 4px;
                background-color: $MAIN_BG_COLOR;
                border: none;
                font-weight: normal;
                font-size: 13px;
                color: $INPUT_MAIN_COLOR;
                font-family: 'Lato';
              }
        }
        .ant-input-suffix {
            svg {
              width: 15px;
              height: 15px;
              color: $INPUT_MAIN_COLOR;
            }
          }
    }
    .header_title {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #edf0f2;
      height: 55px;
      font-weight: normal;
      font-size: 19px;
      line-height: 22px;
      color: $BASE_BLACK_COLOR;
      cursor: pointer;
        .back_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          background-color: $SECONDARY_BG_COLOR;
          border-radius: 50%;
          padding: 8px 8px;
          margin-right: 14px;
          svg {
            width: 22px;
            height: 22px;
            color: $BASE_LIGHT_BLACK_COLOR;
          }
        }
    }
    .btn_wrapper {
        display: flex;
        justify-content: flex-start;
        padding-top: 25px;
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 169px;
            background-color: $BASE_YELLOW_COLOR;
            border: none;
    
            span,
            svg {
                color: $BASE_WHITE_COLOR;
                font-size: 13px;
            }
        }
    }
}