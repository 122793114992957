.navbar_layout {
  min-width: $NAV_BAR_WIDTH;
  background-color: $BASE_BLACK_COLOR;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 28px 16px;
  min-height: 100vh;

  .button_fifa {
    height: 16px;
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    cursor: pointer;

    .icon_fifa {
      height: 13px;
      width: 15px;
      margin-right: 16px;
      border: 1.5px solid #838383;
      border-radius: 1px;
      position: relative;

      .left_top, .right_bottom {
        height: 3px;
        width: 4px;
        position: absolute;
        border-radius: 1px;
        border: 1.5px solid #838383;
      }

      .right_bottom {
        bottom: 1.5px;
        right: 1.5px;
        border-left: none;
        border-top: none;

      }

      .left_top {
        top: 1px;
        left: 1px;
        border-bottom: none;
        border-right: none;
      }
    }

    .button_new_admin {
      height: 100%;
      color: #838383;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
    }
  }


  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;

    .avatar {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background-color: $AVATAR_BG_COLOR;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 15px;
        color: $BASE_WHITE_COLOR;
      }
    }
  }

  nav {
    padding: 0 15px;

    p {
      font-style: italic;
      font-weight: normal;
      font-size: 11px;
      line-height: 12px;
      color: $NAV_GROUP_TEXT_COLOR;
      margin-bottom: 16px;
    }

    a {
      text-decoration: none;
    }

    .navItem::selection, p::selection {
      color: #fff;
      background-color: #1890ff;
    }
    .navItem {
      cursor: pointer;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      margin-bottom: 16px;

      color: $NAV_BAR_TEXT_COLOR;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 16px;
        color: $BASE_WARM_GRAY_COLOR;
        margin-top: -3px;
      }
    }

    .active {
      .navItem {
        svg {
          color: $BASE_WHITE_COLOR;

          path {
            fill: $BASE_WHITE_COLOR;
          }
        }
        color: $BASE_WHITE_COLOR;
        font-size: 15px;
      }
    }
  }

  .sign-off::selection {
    color: #fff;
    background-color: #1890ff;
  }
  .sign-off {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $NAV_BAR_TEXT_COLOR;
    padding: 0 15px;
    display: flex;
    cursor: pointer;

    svg {
      width: 15px;
      height: 15px;
      margin-right: 16px;
    }
  }
}
