.configure_region_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 48px 47px 35px;

    .ant-form-item {
      margin-bottom: 0px;
    }

    .modal_header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
      button {
        min-width: 144px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        background-color: $BASE_BLACK_COLOR;
        border: none;
        color: $BASE_WHITE_COLOR;
        font-size: 13px;

        svg {
          width: 16px;
          height: 16px;
          margin-left: 16px;
        }
      }
    }

    .modal_content {
      margin-top: 41px;
      .restaurant_list {
        max-height: 400px;
        overflow-y: auto;
      }
      .address_wrapper {
        width: 100%;
        position: relative;
        .location-search-input {
          position: relative;
          top: 0;
          left: 0;
          border-radius: 11.5px;
          height: 38px;
          border: none;
          background-color: transparent;

          input {
            position: absolute;
            width: 100%;
            height: 38px;
            top: 0;
            left: 0;
            padding-left: 30px;
            border-radius: 11.5px;
          }

          .ant-input-prefix {
            z-index: 1;
            height: 14px;
            width: 14px;
            color: $MAP_ICON_COLOR;
            margin-top: 8px;
          }
        }
        .autocomplete-dropdown-container {
          width: 100%;
          left: 0;
          top: 100px;
        }
      }
      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-left: 0px;
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 30px;
        }

        .ant-checkbox {
          margin-right: 20px;
        }

        .ant-checkbox-inner {
          background-color: $OPTION_BG_COLOR;
        }

        .ant-checkbox-inner {
          border: none;
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner::after {
            width: 16px;
            height: 16px;
            background-color: $BASE_YELLOW_COLOR;
            transform: rotate(0deg) translate(-50%, -50%);
            border: 2px solid $BASE_WHITE_COLOR;
            left: 50%;
            top: 50%;
          }
        }

        .ant-checkbox-checked::after {
          border: none;
        }

        .description {
          margin-bottom: 0px;
        }
      }
    }
  }
}
