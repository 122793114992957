.top_sales_list {
  .top_sales_title {
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: $BASE_DARK_GRAY_COLOR;
    margin-bottom: 36px;
    text-align: center;
  }

  .top_five_sales_wrapper {
    display: flex;
    flex-direction: column;

    .food_item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 28px;
      }

      .food_img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 63px;
        height: 63px;
        background: $BASE_WHITE_COLOR;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.0294744);
        border-radius: 8px;
        margin-right: 20px;
        padding: 8px;

        img {
          height: 100%;
        }
      }

      .food_info {
        display: flex;
        flex: 1;
        flex-direction: column;

        .name {
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          color: $TOP_SALES_LIST_FOOD_TITLE_COLOR;
        }

        .sold-count {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: $BASE_DARK_GRAY_COLOR;
        }
      }
    }
  }
}
