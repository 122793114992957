.banner_layout {
  height: 100%;
  .banner_setting_wrapper {
    height: calc(100% - 82px);
    display: flex;
    min-width: 1124px;
    padding: 0px 45px;
    border-top: 1px solid $MAIN_BORDER_COLOR;
    .title {
      font-size: 17px;
      line-height: normal;
    }
    .description {
      font-size: 13px;
    }
    button {
      font-family: "Lato";
    }
    input {
      height: 40px;
      border-radius: 4px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      color: $BASE_BLACK_COLOR;
      font-size: 13px;
      border: none;
      font-family: "Lato";
    }
    .ant-select-open {
      .ant-select-selection-item {
        color: black;
      }
    }
    .ant-select {
      height: 40px;
      .ant-select-selector {
        height: 40px;
        align-items: center;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: "Lato";
        border-radius: 8px;
        margin-top: 0px;
      }
    }
    .application_scheme_wrapper {
      width: 25%;
      padding-top: 31px;
      padding-right: 42px;
      border-right: 1px solid $MAIN_BORDER_COLOR;
      .description {
        margin-top: 26px;
      }
    }
    .application_detail_wrapper {
      width: 38%;
      padding: 31px 39px 0px;
      border-right: 1px solid $MAIN_BORDER_COLOR;
      .title {
        font-size: 12px;
      }
      .banner_upload_wrapper {
        margin-bottom: 45px;
        .web_banner_wrapper,
        .mobile_banner_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .photo_info {
            padding-right: 10px;
            button {
              background-color: $FORM_ELEMENT_BG_COLOR;
              border: none;
            }
          }
          .file_input {
            display: none;
          }
          .photo_area {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 165.48px;
            height: 118.29px;
            border-radius: 10px;
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }
        .mobile_banner_wrapper {
          margin-top: 36px;
          .photo_area {
            width: 74px;
          }
        }
      }
      .aditional_detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        p {
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .ant-legacy-form-item {
          margin-bottom: 0px;
          height: 40px;
        }
        .ant-switch-checked {
          background-color: $BASE_GREEN_COLOR;
        }
      }
      .update-image-date-wrapper {
        margin-top: 30px;
        .update-image-date {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 10px;
          background-color: $FORM_ELEMENT_BG_COLOR;
          border-radius: 4px;
          button {
            height: 100%;
            background: $BASE_YELLOW_COLOR;
            color: $BASE_WHITE_COLOR;
            font-size: 8px;
          }
        }
      }
    }
    .social_links_wrapper {
      width: 38%;
      padding: 31px 39px 0px;
      .title {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .whatsapp_title {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 20px;
    }
    .otp_settings_wrapper_true {
      display: block;
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      max-height: 1000px;
      overflow: hidden;
      transition:
        opacity 0.3s ease,
        transform 0.3s ease,
        max-height 0.3s ease;
    }

    .otp_settings_wrapper_false {
      display: block;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.95);
      max-height: 0;
      overflow: hidden;
      transition:
        opacity 0.3s ease,
        transform 0.3s ease,
        max-height 0.3s ease;
    }

    .whatsapp_number {
      margin-top: 14px;

      .react-tel-input {
        .flag-dropdown {
          border: none;
          height: 40px;
        }
        input {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          background-color: $FORM_ELEMENT_BG_COLOR;
          border: none;
        }
      }
    }

    .text_switcher {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      margin-bottom: 24px;
      gap: 20px;

      p {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        position: relative;
      }

      .otp_tooltip {
        position: absolute;
        top: -4px;
        right: -24px;
      }
      .ant-legacy-form-item {
        margin-bottom: 0px;
        height: 40px;
        min-width: 45px;
      }
      .ant-switch-checked {
        background-color: $BASE_GREEN_COLOR;
      }
    }
  }
}
