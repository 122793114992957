.offers_preview_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 37px 32px 32px;

    .modal_header {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .modal_header_title {
        margin: 0;
        font-size: 17px;
        font-weight: 700;
        font-family: 'Lato';
      }
    }
  }
  .back_button {
    cursor: pointer;
    background-color: $SECONDARY_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-right: 13px;

    :hover {
      color: $BASE_LIGHT_GRAY_COLOR;
    }
  }
}

.modal_body {
  margin-top: 32px;
  display: flex;
  grid-gap: 24px;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;

  .item_title {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Lato';
  }

  .item_card {
    padding: 16px;
    border: 1px solid #cfcfcf;
    border-radius: 8px;

    .catalog_preview {
      img {
        width: 180px;
        height: 139px;
      }
    }
  }
}

.catalog_preview {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 1.82px 9.11px 0px #00000012;

  .body {
    margin-top: 4px;
    padding: 8px;

    .title {
      font-size: 14px;
      font-weight: 700;
      font-family: 'SpeedeeBold';
      line-height: 16px;
    }

    .time {
      margin-top: 8px;
      display: flex;

      .time_icon {
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 2px;
      }

      .time_value {
        font-size: 12px;
        margin-left: 4px;
        margin-bottom: 0;
        line-height: 18px;
      }
    }
  }
}

.detail_preview {
  .body {
    margin-top: 14px;

    .title {
      font-size: 14px;
      font-weight: 700;
      font-family: 'SpeedeeBold';
      line-height: 16px;
    }

    .desc {
      margin-top: 5px;
      color: #6f6f6f;
      font-size: 8px;
      font-weight: 400;
      font-family: 'SpeedeeRegular';
      line-height: 13.5px;
      letter-spacing: -0.07px;
    }

    .conditions {
      margin-top: 5px;

      .condition {
        display: flex;
        align-items: center;
        font-size: 8px;
        font-weight: 400;
        font-family: 'SpeedeeRegular';
        line-height: 14px;
        margin-bottom: 0;

        .checkmark {
          margin-right: 2px;
        }
      }

      .terms {
        display: flex;
        align-items: center;
        font-size: 8px;
        font-weight: 400;
        font-family: 'SpeedeeRegular';
        line-height: 14px;
        margin-bottom: 0;
        margin-top: 4px;
        text-decoration: underline;
        color: $On_the_way;
      }

      .location {
        display: flex;
        align-items: center;
        font-size: 8px;
        font-weight: 400;
        font-family: 'SpeedeeRegular';
        line-height: 14px;
        margin-bottom: 0;
        max-width: 180px;
      
        .descriptionLocation {
          max-width: 145px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      
        .checkmark {
          margin-right: 2px;
        }

        .chevron {
          font-size: 13px;
          color: $BASE_GRAY_COLOR;
        }
      }
    }
  }

  .subtitle {
    margin-top: 10px;
    font-size: 10px;
    font-weight: 700;
    font-family: 'SpeedeeBold';
    line-height: 16px;
  }

  .redirect {
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 7px 4px;

    .redirect_inner {
      display: flex;
      align-items: center;

      .dine_in_table {
        margin-right: 4px;
      }

      .text {
        font-size: 8px;
        font-weight: 400;
        font-family: 'SpeedeeRegular';
        line-height: 10px;
        margin-bottom: 0;
      }
    }

    .chevron {
      font-size: 13px;
      color: $BASE_GRAY_COLOR;
    }
  }
}