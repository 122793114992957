.offers_view_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 22px 0px 35px;

    .modal_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-right: 10px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .name_wrapper {
        h3 {
          margin-bottom: 0px;
          margin-right: 5px;
        }

        .name_wrapper_id {
          padding-top: 5px;
          color: #7B7B7B;
          font-size: 12px;
          font-weight: 400;
          font-family: 'Lato';
          line-height: 14.4px;
        }
      }
    }
  }
  .close_modal {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: $BASE_BLACK_COLOR;
    :hover {
      color: $BASE_LIGHT_GRAY_COLOR;
    }
  }
}

.new_offers_form_wrapper {
  overflow: hidden;
  .offers_input {
    border-bottom: 1px solid $DIVIDER_BG_COLOR;
    p {
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
  .sub_title {
    font-size: 18px;
    color: #b5b5b5;
  }
  input {
    height: 32px;
    width: 100%;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    font-size: 13px;
    border: none;
    margin-bottom: 10px;
  }
  .btn_arrows {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-right: 10px;
    border-bottom: 1.5px solid #edf0f2;
    .ant-btn-default:hover {
      color: $BASE_GRAY_COLOR;
    }
    .arrow_left {
      background-color: white;
      border: none;
      box-shadow: none;
      align-items: center;
      display: flex;
      padding: 0;
      gap: 5px;
    }
    .arrow_right {
      background-color: white;
      border: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      margin-right: 0;
      padding: 0;
      gap: 5px;
    }
  }
  .side_btns,
  .side_btns_cat {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    .aditional_detail {
      display: flex;
      align-items: center;
      .ant-form-item {
        margin-bottom: 0px;
      }
      .ant-switch-checked {
        background-color: $BASE_GREEN_COLOR;
      }
  
      p {
        font-weight: bold;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
        margin-right: 12px;
        margin-bottom: 3.5px;
      }
    }
    .btn_wrapper_offer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      color: $BASE_WHITE_COLOR;
      .ant-btn-default:disabled {
        color: $BASE_GRAY_COLOR;
        svg {
          color: $BASE_GRAY_COLOR;
          font-size: 13px;
        }
      }
      .ant-btn-default {
        color: $BASE_WHITE_COLOR;
        svg {
          color: $BASE_WHITE_COLOR;
          font-size: 13px;
        }
      }
      .cancel_btn_offers {
        color: $BASE_BLACK_COLOR;
        cursor: pointer;
        line-height: 32px;
        margin-right: 15px;
        margin-bottom: 5px;
        /* :hover {
          color: $BASE_DARK_BLACK_COLOR;
        } */
      }
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 100px;
        background-color: $BASE_BLACK_COLOR;
        border: none;
        span,
        svg {
          color: $BASE_WHITE_COLOR;
          font-size: 13px;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .offers_tab {
    height: auto;
    margin-bottom: 16px;
    padding-right: 10px;

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding: 16px 4px;
        font-family: 'Lato';
        .ant-tabs-tab-btn {
          padding-left: 4px;
          padding-right: 4px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #6b7280;
          font-family: 'Lato';
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #212121;
          font-family: 'Lato';
          text-shadow: inherit;
        }
      }
      .ant-tabs-ink-bar {
        background: black;
        height: 1px;
      }
    }
  }

  .tab_content {
    height: 360px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin; 
    scrollbar-color: #ABABAB #F4F5F7;
    padding-right: 10px;
  }
  
  .tab_content::-webkit-scrollbar {
    width: 2px;
  }
  
  .tab_content::-webkit-scrollbar-thumb {
    background-color: #ABABAB;
    border-radius: 10px;
  }
  
  .tab_content::-webkit-scrollbar-track {
    background-color: #F4F5F7;
    border-radius: 10px;
  }
  
  .tab_wrapper {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: calc(100% + 40px);
    margin-left: 0;
    margin-right: 0;
  }
  .tabs_scroll {
    a {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #6b7280;
    }
  }
  .active {
    border-bottom: 1.5px solid black;

    a {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #212121;
    }
  }
}