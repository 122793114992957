.version_page_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .title {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $MAIN_TEXT_COLOR;
    margin-bottom: 16px;
  }
  .button {
    display: flex;
    align-items: center;
    background-color: #c69797;
  }
}
