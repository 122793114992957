.order_filter_layout {
  padding: 20px 17px 20px 25px;
  border-top: 1px solid $MAIN_BORDER_COLOR;
  border-bottom: 1px solid $MAIN_BORDER_COLOR;
  display: flex;

  .active_states {
    width: 180px;
    margin-right: 10px;
    height: fit-content;
    .ant-select-selector {
      min-height: 40px;
      height: fit-content;
      padding: 10;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Lato';
      border-radius: 8px;
      .ant-select-selection-item {
        background-color: #fafafa;
      }
      .ant-select-selection__rendered {
        padding: 0;
      }
    }
  }

  .all_restaurants {
    width: 180px;
    margin-right: 10px;
    height: fit-content;
    .ant-select-selector {
      min-height: 40px;
      height: fit-content;
      padding: 10;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Lato';
      border-radius: 8px;
      .ant-select-selection-item {
        max-width: 135px;
        background-color: #fafafa;
      }
      .ant-select-selection__rendered {
        padding: 0;
      }
    }
  }

  .ant-picker {
    height: 32px;
    background-color: $MAIN_BG_COLOR;
    border: none;
    border-radius: 4px;
    width: 150px;
    input {
      background-color: $MAIN_BG_COLOR;
      border: none;
      color: $INPUT_MAIN_COLOR;
      border-radius: 4px;
    }

    svg {
      height: 15px;
    }
  }

  .start_date,
  .end_date {
    margin-right: 10px;
    height: 40px;
    .ant-picker-input {
      .ant-picker-clear {
        margin-right: 4px;
        svg {
          background-color: $MAIN_BG_COLOR;
        }
      }
    }
  }

  .sort_by {
    height: 40px;
    min-width: 200px;
    margin-right: 10px;
    .ant-select-selector {
      height: 40px;
      align-items: center;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Lato';
      border-radius: 8px;
      color: black;
    }
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }

  .apply_filters_btn, .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 4px;
    background-color: $BASE_BLACK_COLOR;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: $BASE_WHITE_COLOR;
    padding: 0 26px;
    font-family: 'Lato';

    svg {
      height: 15px;
      margin-left: 17px;
      color: $BASE_WHITE_COLOR;
    }
  }
}
