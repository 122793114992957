.configure_region_layout {
  display: flex;
  flex-direction: column;
  min-width: 1124px;

  .region_description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 29px 39px 15px 39px;
    border-top: 1px solid $DIVIDER_BG_COLOR;
    border-bottom: 1px solid $DIVIDER_BG_COLOR;

    .description {
      line-height: 1.5;
    }

    div {
      flex: 1;
    }

    div:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .region_setting_wrapper {
    padding: 0px 45px;
    margin-top: 34px;
    display: flex;
    height: calc(100vh - 250px);

    .card {
      width: 25%;
      height: 100%;
      padding: 16px 16px 0px 0px;

      &:not(:last-child) {
        margin-right: 26px;
        border-right: 1px solid $DIVIDER_BG_COLOR;
      }

      .card_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin-bottom: 0px;
        }

        svg {
          cursor: pointer;
          color: $BASE_BLACK_COLOR;
        }
      }

      .card_list {
        margin-top: 26px;
        .description {
          word-break: break-all;
        }
        > div {
          cursor: pointer;
        }
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            height: 24px;
          }
        }
        .active {
          .title {
            color: $BASE_YELLOW_COLOR;
          }
        }
      }
    }
  }
}
