.overview_layout {
  .overview_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 43px;
    min-width: 1024px;

    .status_wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .overview_status_card {
        height: 147px;
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_DARK_GRAY_COLOR;
        p {
          font-family: 'Lato';
          font-weight: 500;
          margin-bottom: 24px;
        }
      }
    }

    .sale_wrapper {
      display: flex;
      margin: 37px 0px;

      .graph_wrapper {
        display: flex;
        flex-direction: column;
        width: 70%;

        .chart_wrapper {
          width: 100%;
          background-color: $MAIN_BG_COLOR;
          padding: 25px 0px 15px 0px;

          .chart_title {
            padding: 0 30px;
            margin-bottom: 26px;

            p {
              font-weight: normal;
              font-size: 13px;
              line-height: 14px;
              color: $BASE_DARK_GRAY_COLOR;
            }
          }

          .chart_period {
            display: flex;
            padding: 0 30px;
            justify-content: flex-end;
            margin-top: 20px;

            p {
              font-weight: bold;
              font-size: 11px;
              line-height: 12px;
            }

            .last_week {
              margin-right: 17px;
              color: $BASE_BLUE_COLOR;
            }

            .current_week {
              color: $BASE_YELLOW_COLOR;
            }
          }
        }

        .blank_wrapper {
          height: calc(100% - 226px);
          background-color: $MAIN_BG_COLOR;
          margin-top: 26px;
        }
      }
      .top_sale_wrapper {
        background-color: $MAIN_BG_COLOR;
        width: 30%;
        margin-left: 43px;
        padding: 25px 30px 38px 25px;
      }
    }
  }
}
