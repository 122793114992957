.distribution_monitor_layout {
  display: flex;
  flex-direction: column;
  min-width: 1124px;

  .content_header_layout {
    border-bottom: 1px solid $MAIN_BORDER_COLOR;
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }
  .ant-select {
    width: 100%;
    min-width: 170px;
    height: 40px;
    .ant-select-selector {
      height: 40px;
      align-items: center;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      border-radius: 8px;
      .ant-select-selection__rendered {
        padding: 0;
        margin: 0px;
        .ant-select-selection-selected-value {
          display: flex !important;
          align-items: center;
        }
      }
    }
  }
  .distribution_monitor_wrapper {
    display: flex;

    .restaurant_filter_wrapper {
      width: 20%;
      padding: 16px 16px 0px 0px;
      margin-right: 26px;
      border-right: 1px solid $DIVIDER_BG_COLOR;
      background: $SECONDARY_BG_COLOR;
      padding: 31px 26px;
      > div {
        margin-bottom: 31px;
      }
      .title {
        margin-bottom: 17px;
      }
      .ant-select-selector {
        min-width: 170px;
      }
    }

    .ambassador_detail_wrapper {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      padding: 31px 26px;

      .profile_statistic_layout {
        margin: 0;
        border-top: none;
      }
      .global_map_title {
        margin: 31px;
      }
      .ambassador_map_layout {
        width: 100%;
      }
    }
  }
}
