.available_restaurant_layout {
  display: flex;
  flex-direction: column;
  min-width: 1124px;

  .active {
    .title {
      color: $BASE_YELLOW_COLOR;
    }
  }

  .ant-select-arrow {
    margin-top: 0px;
  }

  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }
  .ant-select {
    .ant-select-selection {
      height: auto;
      padding: 0;
      .ant-select-selection__rendered {
        max-width: 70%;
        padding: 0;
      }
    }
  }
  .ant-input-affix-wrapper {
    height: fit-content;
    width: 100%;
    margin-left: auto;
    margin-top: 14px;

    input {
      border-radius: 4px;
      background-color: $MAIN_BG_COLOR;
      border: none;
      font-weight: normal;
      font-size: 13px;
      color: $INPUT_MAIN_COLOR;
    }

    .ant-input-suffix {
      svg {
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
  }

  .list {
    margin-top: 36px;
    .item {
      margin-bottom: 23px;
      cursor: pointer;

      .status-circle {
        width: 8px;
        height: 8px;
        min-width: 8px;
        min-height: 8px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .title,
      .description {
        margin-bottom: 0px;
      }
      .title {
        margin-right: 10px;
      }
    }
  }
  .card_list {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    .list {
      overflow-y: auto;
    }
  }
  .restaurants_wrapper {
    padding: 0px 45px;
    display: flex;
    height: calc(100vh - 250px);

    .card_header {
      p {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: $BASE_BLACK_COLOR;
      }
    }
    .availabe_restaurant {
      width: 20%;
      padding: 16px 16px 0px 0px;
      margin-right: 26px;
      border-right: 1px solid $DIVIDER_BG_COLOR;

      .card_list {
        margin-top: 26px;
      }
    }

    .empty_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .restaurant_info {
      width: 100%;
      display: flex;

      .card {
        height: 100%;
        padding: 16px 16px 0px 0px;

        &:not(:last-child) {
          width: 25%;
          margin-right: 26px;
          border-right: 1px solid $DIVIDER_BG_COLOR;
        }

        &:last-child {
          width: 50%;
        }

        .card_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            margin-bottom: 0px;
          }
        }

        .card_list {
          margin-top: 26px;
          .profile_info {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $DIVIDER_BG_COLOR;
            padding-bottom: 27px;
            margin-bottom: 25px;

            .avatar {
              width: 49px;
              height: 49px;
              border-radius: 50%;
              background-color: $USER_AVATAR_BG_COLOR;
              margin-right: 24px;
            }
            .description {
              margin-bottom: 0px;
            }
          }
          .profile_detail {
            display: flex;
            > div {
              flex: 1;
              margin-bottom: 28px;

              > div {
                display: flex;
                flex-direction: column;
              }
            }
          }

          .ambassador_map_layout {
            height: 250px;
          }

          .ant-select-open {
            .ant-select-selection-item {
              color: black;
            }
          }

          .schedule_card {
            background: $MAIN_BG_COLOR;
            border-radius: 8px;
            padding: 19px;
            margin-top: 20px;
            margin-bottom: 34px;

            .schedule_range {
              display: flex;
              > div {
                flex: 1;
                display: flex;
                align-items: center;
                margin-top: 16px;
                .description {
                  margin-bottom: 0px;
                  margin-right: 13px;
                }
              }
              .dropdown {
                width: 76px;
                background-color: $BASE_WHITE_COLOR;
                color: $BASE_BLACK_COLOR;
              }
              .ant-select {
                height: 32px;
                min-width: 76px;
                .ant-select-selection {
                  height: 32px;
                  background-color: $BASE_WHITE_COLOR;
                  padding: 0;
                }
              }
            }
            .btn_wrapper {
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;

              button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-width: 169px;
                background-color: $BASE_YELLOW_COLOR;
                border: none;

                span,
                svg {
                  color: $BASE_WHITE_COLOR;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}
