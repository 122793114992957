.catalogue_sync_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .modal_header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
    }

    .modal_footer {
      display: flex;
      justify-content: flex-end;

      button:hover {
        color: $BASE_WHITE_COLOR;
      }

      button {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        background-color: $BASE_BLACK_COLOR;
        border: none;
        color: $BASE_WHITE_COLOR;
        font-size: 13px;
        margin-top: 18px;

        svg {
          width: 16px;
          height: 16px;
          margin-left: 16px;
        }
      }
    }
  }
}
