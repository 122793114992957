.catalogue_table_layout {
  .table_header {
    display: flex;
    padding: 12px 15px;
    color: $BASE_BLACK_COLOR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    .food_photo,
    .sku,
    .base_price {
      width: 7%;
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    .name,
    .category,
    .schedule,
    .last_import {
      width: 17%;
      display: flex;
      align-items: center;
    }

    .food_photo {
      padding-left: 20px;
    }

    .actions {
      width: 11%;
      text-align: center;
      justify-content: center;
    }
  }

  .table_content {
    height: calc(100vh - 350px);
    overflow: auto;
    .table_item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border-top: 1px solid $MAIN_BORDER_COLOR;

      .food_photo,
      .sku,
      .base_price {
        width: 7%;
        display: flex;
        flex-direction: column;
        .photo_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 54px;
          height: 54px;
          background-color: $SECONDARY_BG_COLOR;
          border-radius: 11.5px;

          img {
            height: 100%;
          }
        }
      }

      .name,
      .category,
      .schedule,
      .last_import {
        width: 17%;
        display: flex;
        flex-direction: column;
      }

      .actions {
        width: 11%;
        display: flex;
        justify-content: center;

        button {
          width: 27px;
          height: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background-color: $TABLE_ACTION_BTN_BG_COLOR;
          border: none;

          svg {
            width: 12px;
            height: 12px;
          }
        }

        button:not(:last-child) {
          margin-right: 10px;
        }
      }

      .sku,
      .last_import {
        .number {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 0px;
        }
        .sku_id,
        .time {
          font-weight: normal;
          font-size: 11px;
          line-height: 12px;
          color: $BASE_LIGHT_GRAY_COLOR;
          margin-bottom: 0px;
        }
        .date {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 0px;
        }
      }

      .name,
      .category,
      .schedule,
      .base_price {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $BASE_BLACK_COLOR;
        
        p {
          margin-bottom: 0px;
        }

        .channel {
          width: 58px;
          text-align: center;
          background-color: $SECONDARY_BG_COLOR;
          border-radius: 4px;
          font-size: 10px;
          margin-right: 4px;
        }
        .promo {
          text-align: center;
          background-color: $BASE_YELLOW_COLOR;
          padding: 0 8px;
          border-radius: 4px;
          font-size: 10px;
          margin-right: 4px;
        }
        .hidden {
          text-align: center;
          background-color: #d2e6f0;
          padding: 0 8px;
          border-radius: 4px;
          font-size: 10px;
        }
      }
    }

    .table_item:nth-child(even) {
      background: $TABLE_BG_COLOR;
    }

    .state_A::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $BASE_GREEN_COLOR;
    }

    .state_I::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $BASE_DARK_BLACK_COLOR;
    }
  }

  .pagination_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .ant-pagination-prev, .ant-pagination-next {
      button {
        border: 1px solid $BORDER_COLOR_PAGINATION;
        border-radius: 4px;
      }
    }
    .ant-pagination-item {
      border: 1px solid $BORDER_COLOR_PAGINATION;
      border-radius: 4px;
      a {
        color: $ICON_SEARCH_INPUT;
      }
    }
    .ant-pagination-item-active {
      border-width: 1px;
      border-color: $BORDER_COLOR_PAGINATION_ACTIVE;
      a {
        color: $BORDER_COLOR_PAGINATION_ACTIVE;
      }
    }
    .ant-pagination-options {
      height: 40px;
      .ant-select-arrow {
        margin-top: -2px;
      }
      .ant-select-selector {
        width: 140px;
        align-items: center;
        height: 40px;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
      }
    }
    .total {
      position: relative;
      left: 15%;
      color: $BASE_LIGHT_GRAY_COLOR;
      font-size: 15px;
    }
  }
}

.ant-tooltip-content {
  .ant-tooltip-arrow {
    &::before {
      background-color: $TOOLTIP_BG_COLOR;
    }
  }
  .ant-tooltip-inner {
    display: flex;
    align-items: center;
    background-color: $TOOLTIP_BG_COLOR;
    padding: 4px 13px;
  }
}
