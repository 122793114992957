.deliveryzones_layout {
  .description_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 29px 51px;
    border-top: 1px solid $MAIN_BORDER_COLOR;
    border-bottom: 1px solid $MAIN_BORDER_COLOR;
    .title {
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      color: $BASE_BLACK_COLOR;
      margin-bottom: 9px;
    }
    .subtitle {
      max-width: 900px;
      line-height: 1.5;
      color: $ICON_SEARCH_INPUT;
      font-size: 14px;
    }
    p {
      line-height: 1.5;
      color: $ICON_SEARCH_INPUT;
      font-size: 14px;
      margin-bottom: 0px;
    }
  }
  .global_areas_map {
    padding: 0 51px;
  }
}
