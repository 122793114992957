.distribution_table_layout {
  .not_assigned {
    color: $BASE_LIGHT_GRAY_COLOR !important;
  }
  .table_header {
    display: flex;
    padding: 12px 15px;
    color: $BASE_BLACK_COLOR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    .sector_id {
      width: 15%;
      display: flex;
      align-items: center;
    }

    .name,
    .restaurants,
    .ambassadors {
      width: 17%;
      display: flex;
      align-items: center;
    }
    .order_now,
    .sector_state {
      width: 12%;
      display: flex;
      align-items: center;
    }
    .actions {
      width: 10%;
      text-align: center;
      justify-content: center;
    }
  }

  .table_content {
    height: calc(100vh - 400px);
    overflow: auto;
    .table_item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border-top: 1px solid $MAIN_BORDER_COLOR;

      .title {
        font-size: 13px;
        line-height: 18px;
        color: $BASE_BLACK_COLOR;
        font-weight: normal;
        margin-bottom: 0px;
      }
      .subtitle {
        font-size: 11px;
        line-height: 12px;
        font-weight: normal;
        color: $BASE_LIGHT_GRAY_COLOR;
      }

      .sector_id {
        width: 15%;
        display: flex;
        flex-direction: column;
        .title {
          font-weight: bold;
        }
      }

      .name,
      .restaurants,
      .ambassadors {
        width: 17%;
        display: flex;
        align-items: center;
        padding: 0 5px 0 0;
      }
      .ambassadors {
        .active-count {
          color: $BASE_GREEN_COLOR;
        }
      }
      .order_now,
      .sector_state {
        width: 12%;
        display: flex;
        align-items: center;
      }
      .sector_state {
        .title {
          width: 93px;
          padding: 3px 0px;
          border-radius: 25px;
          font-size: 10px;
          color: $BASE_WHITE_COLOR;
          text-align: center;
        }
        .demand_H {
          background-color: $BASE_DARK_RED_COLOR;
        }
        .demand_M {
          background-color: $BASE_YELLOW_COLOR;
        }
        .demand_L {
          background-color: $BASE_GREEN_COLOR;
        }
      }
      .actions {
        width: 10%;
        display: flex;
        justify-content: center;

        button {
          width: 27px;
          height: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background-color: $TABLE_ACTION_BTN_BG_COLOR;
          border: none;

          svg {
            width: 12px;
            height: 12px;
          }
        }

        button:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .table_item:nth-child(even) {
      background: $TABLE_BG_COLOR;
    }

    .table_item::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $BASE_GREEN_COLOR;
    }

    .table_H {
      &::before {
        background-color: $BASE_DARK_RED_COLOR;
      }
    }
    .table_M {
      &::before {
        background-color: $BASE_YELLOW_COLOR;
      }
    }
    .table_L {
      &::before {
        background-color: $BASE_GREEN_COLOR;
      }
    }
  }

  .pagination_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .ant-pagination-prev, .ant-pagination-next {
      button {
        border: 1px solid $BORDER_COLOR_PAGINATION;
        border-radius: 4px;
      }
    }
    .ant-pagination-item {
      border: 1px solid $BORDER_COLOR_PAGINATION;
      border-radius: 4px;
      a {
        color: $ICON_SEARCH_INPUT;
      }
    }
    .ant-pagination-item-active {
      border-width: 1px;
      border-color: $BORDER_COLOR_PAGINATION_ACTIVE;
      a {
        color: $BORDER_COLOR_PAGINATION_ACTIVE;
      }
    }
    .ant-pagination-options {
      height: 40px;
      .ant-select-arrow {
        margin-top: -2px;
      }
      .ant-select-selector {
        width: 140px;
        align-items: center;
        height: 40px;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
      }
    }
  }
}

.ant-tooltip-content {
  .ant-tooltip-arrow {
    &::before {
      background-color: $TOOLTIP_BG_COLOR;
    }
  }
  .ant-tooltip-inner {
    display: flex;
    align-items: center;
    background-color: $TOOLTIP_BG_COLOR;
    padding: 4px 13px;
  }
}
