.confirm_update_csv_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 35px 35px 35px 35px;

    .header {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
    }

    .description {
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .button_container {
        display: flex;
        align-items: center;

        .cancel_button {
          cursor: pointer;
          margin: 0 14px 0 0;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: $BASE_BLACK_COLOR;
        }

        .confirm_button {
          cursor: pointer;
          min-width: 93px;
          max-width: 108px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: none;
          border-radius: 4px;
          font-size: 13px;
          color: $BASE_WHITE_COLOR;
          background-color: $BASE_BLACK_COLOR;

          svg {
            width: 16px;
            height: 16px;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
