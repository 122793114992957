.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
  background-color: $FORM_ELEMENT_BG_COLOR;
  color: $BASE_BLACK_COLOR;
  border-radius: 4px;
  border: none;
  padding: 8px 14px;
  outline: none;
  justify-content: space-between;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  line-height: 14px;

  svg {
    width: 18px;
    color: $INPUT_MAIN_COLOR;
  }
}
