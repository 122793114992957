.order_status_modal {
  .ant-modal-content {
    padding: 0;
  }
  .detail_content {
    .detail_header {
      display: flex;
      align-items: center;
      padding: 22px 0px 12px 22px;

      p {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 17px;
        color: $BASE_BLACK_COLOR;
        margin-right: 15px;
      }
      button {
        width: auto;
        height: 20px;
        border: none;
        border-radius: 8px;
        color: $BASE_WHITE_COLOR;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 12px;
        margin-bottom: 15px;
      }
    }

    .buttons_container {
      display: flex;
      align-items: center;
      padding: 17px 22px;
      background-color: $BASE_DARK_WHITE_COLOR;

      .send_sar_btn {
        margin-right: 12px;

        button {
          font-size: 12px;
          font-family: 'Lato';
          color: $BASE_WHITE_COLOR;
          border-radius: 4px;
          background-color: $BASE_BLACK_COLOR;

          img {
            margin-left: 11px;
          }

          &:hover {
            color: $BASE_WHITE_COLOR;
          }
        }
      }

      .cancel_order_button {
        button {
          font-size: 12px;
          font-family: 'Lato';
          color: $BASE_BLACK_COLOR;
          border-radius: 4px;
          border-color: $BASE_BLACK_COLOR;
          background-color: $BASE_WHITE_COLOR;

          &:hover {
            color: $BASE_DARK_GRAY_COLOR;
            border-color: $BASE_LIGHT_GRAY_COLOR;
          }

          &:disabled {
            color:  $BASE_DARK_GRAY_COLOR;
            border-color: $BASE_LIGHT_GRAY_COLOR;
          }
        }
      }
    }

    .sar_json_list {
      margin-top: 17px;
      padding-left: 26px;

      .sar_list_item {
        margin-bottom: 28px;

        .ant-divider {
          &::before {
            display: none;
          }
          .ant-divider-inner-text {
            font-size: 12px;
            line-height: 14px;
            color: $ORDER_MODAL_GROUP_COLOR;
            padding-left: 0px;
          }
        }
        .json-format {
          position: relative;
          padding: 0 52px;
          &::before {
            position: absolute;
            content: ' ';
            width: 2px;
            height: 100%;
            background: $ORDER_MODAL_ITEMS_LEFT_BAR_COLOR;
            left: 20px;
          }

          & > div {
            overflow-x: auto;
            white-space: pre;
          }
        }
      }
    }
  }
}
