.tabs_view_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 0px 35px;

    .modal_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .name_wrapper {
        h3 {
          margin-bottom: 0px;
        }
      }
    }
  }
  .close_modal {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: $BASE_BLACK_COLOR;
    :hover {
      color: $BASE_LIGHT_GRAY_COLOR;
    }
  }
}

.new_tabs_form_wrapper {
  display: flex;
  padding-top: 16px;
  .tabs_input {
    border-bottom: 1px solid $DIVIDER_BG_COLOR;
    p {
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
  .sub_title {
    font-size: 18px;
    color:#B5B5B5;
  }
  input {
    height: 32px;
    width: 360px;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    font-size: 13px;
    border: none;
    margin-bottom: 30px;
  }
  .side_btns, .side_btns_cat {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    justify-content: right;
    .aditional_detail {
      display: flex;
      align-items: center;
      .ant-form-item {
        margin-bottom: 0px;
      }
      .ant-switch-checked {
        background-color: $BASE_GREEN_COLOR;
      }
  
      p {
        font-weight: bold;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
        margin-right: 19px;
      }
    }
    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ant-btn-default:disabled {
        color: $BASE_WHITE_COLOR;
      }
      .cancel_btn_tabs {
        color: $BASE_BLACK_COLOR;
        cursor: pointer;
        margin-right: 15px;
        line-height: 32px;
        :hover {
          color: $BASE_DARK_BLACK_COLOR;
        }
        margin-bottom: 5px;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 100px;
        background-color: $BASE_BLACK_COLOR;
        border: none;
  
        span,
        svg {
          color: $BASE_WHITE_COLOR;
          font-size: 13px;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
