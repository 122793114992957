.analytical_layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  embed {
    width: 100%;
    height: 100%;
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }
  .analytical_layout_content{
    width: 100%;
    height: 100%;
    background: #f2f2f4;
    .analytical_layout_content_box{
      margin: 22px 34px 50px 22px;
      padding: 26px 30px 23px 27px;
      background: #FFFFFF;
      .analytical_layout_content_box_inside{
        p{
          margin-top: 12px;
          margin-bottom: 20px;
          font-size: 10px;
        }
        .analytical_layout_content_box_inside_top{
          span, p{
            font-size: 11px;
            color: black;
          }
        }

        .analytical_layout_content_box_inside_bottom{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          gap: 12px;
          align-items: end;
          border-left: 1px solid #f3f3f3;
          padding-left: 17px;
          min-height: 60px;
          .analytical_layout_download{
            width: 100%;
            align-items: flex-end;
            .input_id{
              width: 320px;
              height: 32px;
              font-size: 14px;
              border-radius: 4px;
              input {
                font-family: 'Lato';
              }
            }
            svg {
              color: $ICON_SEARCH_INPUT;
            }
          }
          > div{
            display: flex;
            flex-direction: column;
            font-size: 11px;
          }
          .sort_by_custom {
            min-width: 150px;
            height: 32px;
            margin-top: 3px;
            .ant-select-selector {
              height: 32px;
              align-items: center;
              border: none;
              background-color: $FORM_ELEMENT_BG_COLOR;
              font-weight: normal;
              font-size: 13px;
              font-family: 'Lato';
              border-radius: 8px;
            }
            .ant-select-selection__rendered{
              overflow: hidden;
            }
            .ant-select-selection-selected-value{
              font-size: 11px;
            }
          }
          .sort_by{
            min-width: 175px;
            height: 32px;
            margin-top: 3px;
            .ant-select-selector {
              height: 32px;
              align-items: center;
              border: none;
              background-color: $FORM_ELEMENT_BG_COLOR;
              font-weight: normal;
              font-size: 13px;
              font-family: 'Lato';
              border-radius: 8px;
            }
            .ant-select-selection__rendered{
              overflow: hidden;
            }
            .ant-select-selection-selected-value{
              font-size: 11px;
            }
          }
          .start_date_custom {
            height: 32px;
            background-color: $MAIN_BG_COLOR;
            border: none;
            border-radius: 4px;
            width: 130px;
            margin-top: 3px;
            input {
              // max-width: 160px;
              font-size: 11px;

              background-color: $MAIN_BG_COLOR;
              border: none;
              color: $BASE_BLACK_COLOR;
              border-radius: 4px;
            }
            .ant-picker-input {
              .ant-picker-clear {
                margin-right: 4px;
                svg {
                  background-color: $MAIN_BG_COLOR;
                }
              }
            }
            svg {
              height: 15px;
            }
          }
          .ant-picker {
            height: 32px;
            background-color: $MAIN_BG_COLOR;
            border: none;
            border-radius: 4px;
            margin-top: 3px;
            input {
              max-width: 160px;
              font-size: 11px;

              background-color: $MAIN_BG_COLOR;
              border: none;
              color: $BASE_BLACK_COLOR;
              border-radius: 4px;
            }
            .ant-picker-input {
              .ant-picker-clear {
                margin-right: 0px;
                svg {
                  background-color: $MAIN_BG_COLOR;
                }
              }
            }
            svg {
              height: 15px;
            }
          }
          .export_scv_button{
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 149px;
            font-size: 12px;
            height: 32px;
            background-color: #ffc200;
            border: none;
          }
          .input_id{
            margin-top: 3px;
            font-size: 11px;
            width: 160px;
            color: $BASE_BLACK_COLOR;
            height: 32px;
            border-radius: 4px;
          }
        .analytical_alert_success_create{
          button{
            border: none;
            background: #7ed321;
            cursor: pointer;
            border-radius: 5px;
          }
        }
        }
        .request{
          flex-direction: column !important;
          row-gap: 23px;
          .export_scv_button{
            width: 101px;
          }
          .analytical_layout_content_box_request{
            display: flex;
            margin-top: 11px;
            row-gap: 15px;
            align-self: flex-start;
            .sort_by{
              max-width: 165px;
            }
            > div{
              display: flex;
              flex-direction: column;
            }
          }

          > div:last-child{
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            column-gap: 12px;
          }
          > div > div{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }
      }
    }
  }

}
