.offers_draft_filter {
  width: 100%;
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }

  .select {
    height: 40px;
    width: 105px;
    margin-right: 10px;

    .ant-select-selector {
      height: 40px;
      align-items: center;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Lato';
      border-radius: 8px;
      color: black;

      .ant-select-selection-item {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .datepicker {
    height: 40px;
    min-width: 120px;
    max-width: 120px;
    margin-right: 10px;

    background-color: $MAIN_BG_COLOR;
    border: none;
    border-radius: 8px;
    .ant-picker-input {
      .ant-picker-clear {
        svg {
          background-color: $MAIN_BG_COLOR;
          width: 15px;
          height: 15px;
          color: $INPUT_MAIN_COLOR;
        }
      }
      svg {
        background-color: $MAIN_BG_COLOR;
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
  }

  .apply_button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 4px;
    background-color: $BASE_BLACK_COLOR;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: $BASE_WHITE_COLOR;
    padding: 9px 24px 9px 24px;
    margin-right: 10px;

    svg {
      height: 15px;
      margin-left: 6px;
      color: $BASE_WHITE_COLOR;
    }
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $BASE_WHITE_COLOR;
  }

  .ant-input-affix-wrapper {
    height: 32px;
    width: 150px;
    border-color: transparent;
    background-color: $MAIN_BG_COLOR;
    border-radius: 4px;

    &:hover {
      border-color: transparent;
    }

    input {
      border-radius: 4px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      border: none;
      font-weight: normal;
      font-size: 13px;
      color: $INPUT_MAIN_COLOR;
      font-family: 'Lato';
    }

    .ant-input-suffix {
      svg {
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
  }

  .search_container {
    width: auto;
    display: flex;
    justify-content: flex-end;
  }

  .availabilities_containers {
    width: auto;
    display: flex;
    margin-bottom: 10px;
  }
}
