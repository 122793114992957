.ambassador_map_layout {
  position: relative;
  width: 100%;
  height: 486px;

  .profile_statistic_layout {
    position: absolute;
    left: -35%;
    top: -40%;
    z-index: 28;
    padding: 22px 43px;
    border-radius: 6px;
  }
}
