.placed_orders_layout {
  padding: 38px 21px;
  .header_title {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: $BASE_BLACK_COLOR;
    margin-bottom: 30px;
  }
  .table_content {
    max-height: 500px;
    overflow: auto;
    .table_item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
      .bought {
        width: 20%;
        display: flex;
        flex-direction: column;
        .code {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 8px;
        }
        .note {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
        }
        .device {
          width: 58px;
          border-radius: 4px;
          padding: 4px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          color: $BASE_BLACK_COLOR;
          background-color: $TABLE_DEVICE_BG_COLOR;
        }
      }
      .date_time {
        width: 30%;
        display: flex;
        flex-direction: column;

        .time {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
        }

        .note {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
        }

        .article {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 8px;
        }
      }
      .delivery_address {
        width: 50%;
        display: flex;
        flex-direction: column;

        .address1 {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
        }
        .address2 {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
        }
        .product {
          width: 94px;
          border-radius: 4px;
          padding: 4px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          color: $BASE_BLACK_COLOR;
          background-color: $TABLE_DEVICE_BG_COLOR;
          margin-top: 11px;
        }
      }
    }

    .table_item:nth-child(even) {
      background: $TABLE_BG_COLOR;
    }

    .table_item::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
    }

    .order_WP::before {
      background-color: $Waiting_Payment;
    }
    .order_RV::before {
      background-color: $Received;
    }
    .order_IP::before {
      background-color: $In_Production;
    }
    .order_AS::before {
      background-color: $Assigned;
    }
    .order_OW::before {
      background-color: $On_the_way;
    }
    .order_AD::before {
      background-color: $At_the_door;
    }
    .order_CL::before {
      background-color: $Claimed;
    }
    .order_RS::before {
      background-color: $Resolving;
    }
    .order_DD::before {
      background-color: $Delivered;
    }
    .order_CD::before {
      background-color: $Cancelled;
    }
  }

  .pagination_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .ant-pagination-prev, .ant-pagination-next {
      button {
        border: 1px solid $BORDER_COLOR_PAGINATION;
        border-radius: 4px;
      }
    }
    .ant-pagination-item {
      border: 1px solid $BORDER_COLOR_PAGINATION;
      border-radius: 4px;
      a {
        color: $ICON_SEARCH_INPUT;
      }
    }
    .ant-pagination-item-active {
      border-width: 1px;
      border-color: $BORDER_COLOR_PAGINATION_ACTIVE;
      a {
        color: $BORDER_COLOR_PAGINATION_ACTIVE;
      }
    }
    .ant-pagination-options {
      height: 40px;
      .ant-select-arrow {
        margin-top: -2px;
      }
      .ant-select-selector {
        width: 140px;
        align-items: center;
        height: 40px;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
      }
    }
    .total {
      position: relative;
      left: 15%;
      color: $BASE_LIGHT_GRAY_COLOR;
      font-size: 15px;
    }
  }
}
