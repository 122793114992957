.delivery_schedule_layout {
  .delivery_time_schedule {
    margin-top: 20px;
    padding-bottom: 30px;

    .select_time_range {
      display: flex;

      .order_set {
        width: 40%;
        margin-right: 28px;
      }
      .open_time {
        width: 30%;
        margin-right: 28px;
      }
      .close_time {
        width: 30%;
      }
    }
  }
}
