.coupon_view_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .modal_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .name_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        p {
          margin-bottom: 0px;
        }

        h3 {
          margin-bottom: 0px;
        }

        .last_import_time {
          font-weight: normal;
          font-size: 11px;
          line-height: 23px;
          color: $INPUT_MAIN_COLOR;
        }
      }
      .code_wrapper {
        p {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 0px;
        }
      }
    }

    .modal_content {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      margin-bottom: 35px;
      padding: 0 25px;
      align-items: normal;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 12px;
        color: $BASE_BLACK_COLOR;
      }

      .description {
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        color: $BASE_DARK_BLACK_COLOR;
        padding-left: 15px;

        strong {
          color: $BASE_BLACK_COLOR;
        }
      }
    }

    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .aditional_detail {
        display: flex;
        align-items: center;

        p {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-right: 19px;
          margin-top: 10px;
        }

        .ant-switch-checked {
          background-color: $BASE_GREEN_COLOR;
        }
      }

      .btn_wrapper button {
        min-width: 0px;
      }
      .btn_wrapper {
        display: flex;
        align-items: center;

        .delete_btn,
        .edit_btn {
          width: 27px;
          height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background-color: $TABLE_ACTION_BTN_BG_COLOR;
          border: none;

          svg {
            color: $BASE_BLACK_COLOR;
            width: 12px;
            height: 12px;
          }
        }
        .delete_btn {
          margin-right: 10px;
        }
        .edit_btn {
          margin-right: 23px;
        }

        .close_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: none;
          font-size: 13px;
          font-family: 'Lato';

          svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
          }
        }

        .close_btn {
          height: 32px;
          background-color: $BASE_BLACK_COLOR;
          color: $BASE_WHITE_COLOR;
        }
      }
    }
  }
}
