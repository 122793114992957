.operator_agent_edit_layout {
  display: flex;
  flex-direction: column;
  min-width: 1124px;

  .ant-input-password {
    border: 0;
    padding: 0;
    display: flex;
    position: relative;
    margin-top: 10px;
    input {
      padding-left: 12px;
      padding-right: 32px;
      margin-top: 0 !important;
    }
    .ant-input-suffix {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      align-self: flex-end;
      position: absolute;
      right: 8px;
    }
  }

  .operator_agent_wrapper {
    padding: 0px 45px;
    display: flex;
    height: calc(100vh - 100px);
    border-top: 1px solid $DIVIDER_BG_COLOR;
    input {
      height: 40px;
      border-radius: 4px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      color: $BASE_BLACK_COLOR;
      font-size: 13px;
      border: none;
      font-family: 'Lato';
    }

    button {
      font-family: 'Lato';
    }

    .profile_outline {
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      color: $BASE_BLACK_COLOR;
      margin-top: 26px;
    }

    .card {
      width: 33%;
      height: 100%;
      padding: 30px 16px 0px 0px;

      &:not(:last-child) {
        margin-right: 26px;
        border-right: 1px solid $DIVIDER_BG_COLOR;
      }

      .user_avatar {
        display: flex;
        align-items: center;
        margin-top: 21px;
        margin-bottom: 24px;
        .avatar,
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: $USER_AVATAR_BG_COLOR;
          object-fit: cover;
          margin-right: 22px;
          cursor: pointer;
        }
        .title {
          margin-bottom: 0px;
        }
        .file_input {
          display: none;
        }
      }

      .ant-form {
        width: 100%;
        display: flex;
        flex-direction: column;

        .dropdown {
          height: 40px;
        }

        .ant-form-item {
          margin-bottom: 24px;

          .title,
          .ant-input {
            margin-top: 10px;
          }
          .ant-dropdown-trigger {
            margin-top: 18px;
          }

          .has-error {
            .ant-input {
              background-color: $FORM_ELEMENT_BG_COLOR;
            }
            .ant-form-explain {
              margin-top: 5px;
            }
          }
        }

        .password_label {
          display: flex;
          justify-content: space-between;
          .note {
            font-weight: normal;
            font-size: 10px;
            line-height: 11px;
            color: $USER_EDIT_PWD_LABEL_COLOR;
          }
        }
      }

      .user_state {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 37px;

        p {
          margin-right: 17px;
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
        }

        .dropdown {
          width: 193px;
          height: 32px;
          margin-top: -10px;
        }
      }

      .setting_wrapper {
        margin-top: 73px;
        border-top: 1px solid $DIVIDER_BG_COLOR;
        padding-top: 49px;

        .setting {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .ant-switch-checked {
            background-color: $BASE_GREEN_COLOR;
          }
        }
      }
    }

    .profile_outline_wrapper {
      display: flex;
      flex-direction: column;
      padding: 30px 16px 36px 0px;

      .remove_user_wrapper {
        margin-top: auto;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $USER_DELETE_BTN_BG_COLOR;
          border: none;
          border-radius: 4px;
          color: $BASE_WHITE_COLOR;
          font-weight: normal;
          font-size: 13px;

          svg {
            width: 17px;
            height: 17px;
          }
        }
      }
    }

    .error_message {
      color: red;
      margin-top: 4px;
      margin-left: 4px;
    }
  }
}
