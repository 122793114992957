.delete_confirm_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .modal_header {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
    }

    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .btn_wrapper {
        display: flex;
        align-items: center;

        p {
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: $BASE_BLACK_COLOR;
          cursor: pointer;
          margin-right: 14px;
          margin-bottom: 0px;
        }

        .sync_btn {
          min-width: 93px;
          max-width: 108px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: none;
          font-size: 13px;
          background-color: $BASE_BLACK_COLOR;
          color: $BASE_WHITE_COLOR;
          font-family: 'Lato';
          svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
          }
        }
      }
    }
  }
}
