.ots_monitor_filter_layout {
  padding: 52px 17px 39px 25px;

  .ant-input-affix-wrapper {
    height: fit-content;
    width: 186px;
    margin-left: auto;
    border: none;
    border-color: transparent;
    background-color: $MAIN_BG_COLOR;
    border-radius: 4px;

    input {
      border-radius: 4px;
      background-color: $MAIN_BG_COLOR;
      border: none;
      font-weight: normal;
      font-size: 13px;
      color: $INPUT_MAIN_COLOR;
      height: 24px;
      font-family: 'Lato';
    }

    .ant-input-suffix {
      svg {
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
  }

  .ots_monitor_filter_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .ots_monitor_filter_content {
    display: flex;
    margin-top: 24px;

    .ant-select-open {
      .ant-select-selection-item {
        color: black;
      }
    }

    .active_states {
      width: 160px;
      margin-right: 10px;
      height: fit-content;
      .ant-select-selector {
        min-height: 40px;
        height: fit-content;
        padding: 10;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
        .ant-select-selection-item {
          background-color: #fafafa;
        }
        .ant-select-selection__rendered {
          padding: 0;
        }
      }
    }

    .availability_by,
    .action_by {
      height: 40px;
      min-width: 200px;
      margin-right: 10px;
      .ant-select-selector {
        height: 40px;
        align-items: center;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
      }
    }

    .orders_number_filter {
      margin-right: 10px;
      .orders_number_between {
        display: flex;
        align-items: center;
        .between {
          margin-right: 25px;
        }
        .to {
          margin: 0 15px;
        }
        .order_min,
        .order_max {
          width: 100px;
          height: 40px;
          .ant-select-selector {
            height: 40px;
            align-items: center;
            border: none;
            background-color: $FORM_ELEMENT_BG_COLOR;
            font-weight: normal;
            font-size: 13px;
            font-family: 'Lato';
            border-radius: 8px;
            .ant-select-selection__rendered {
              padding: 0;
            }
          }
        }
      }
    }
    .apply_filters_btn, .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      display: flex;
      align-items: center;
      border: none;
      border-radius: 4px;
      background-color: $BASE_BLACK_COLOR;
      font-weight: normal;
      font-size: 13px;
      line-height: 14px;
      color: $BASE_WHITE_COLOR;
      padding: 0 26px;
      margin-top: 20px;
      font-family: 'Lato';

      svg {
        height: 15px;
        margin-left: 17px;
        color: $BASE_WHITE_COLOR;
      }
    }
  }
}
