.save_as_draft_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .header {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: $BASE_BLACK_COLOR;
          cursor: pointer;
          margin-right: 12px;
          margin-bottom: 0;
        }

        .close_button {
          min-width: 130px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          border: none;
          font-size: 13px;
          background-color: $SECONDARY_BG_COLOR;
          color: $BASE_BLACK_COLOR;
          margin-right: 4px;

          &:hover {
            color: $BASE_BLACK_COLOR !important;
          }
        }

        .save_button {
          min-width: 115px;

          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          border: none;
          font-size: 13px;
          background-color: $BASE_BLACK_COLOR;
          color: $BASE_WHITE_COLOR;
        }
        .save_button_primary {
          min-width: 115px;

          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          border: none;
          font-size: 13px;
          background-color: $BASE_YELLOW_COLOR;
          color: $BASE_WHITE_COLOR;
        }
      }
    }
  }
}
