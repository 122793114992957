.deliveryzone_new_layout {
  .main_content {
    padding: 0px 39px;

    .deliveryzone_info_wrapper {
      display: flex;
      flex-direction: column;
      min-width: 1124px;
      border-top: solid 2px $SECONDARY_BG_COLOR;

      input {
        height: 40px;
        border-radius: 4px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        border: none;
      }

      h3 {
        margin-top: 31px;
      }

      .base_info_wrapper {
        .ant-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .ant-form-item {
            flex: 1 1 30%;
            padding-right: 10px;
            margin-bottom: 35px;
            .has-error {
              .ant-input {
                background-color: $FORM_ELEMENT_BG_COLOR;
              }
              .ant-form-explain {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }

    .exclude_map_description,
    .include_map_description {
      margin-top: 52px;
      margin-bottom: 25px;
    }
  }
}
