.coupon_new_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .ant-select-open {
      .ant-select-selection-item {
        color: black;
      }
    }

    input,
    textarea {
      height: 40px;
      border-radius: 4px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      color: $BASE_BLACK_COLOR;
      font-size: 13px;
      border: none;
    }

    input.upper_case_coupon {
      text-transform: uppercase;
    }

    .outcome_wrapper {
      .ant-legacy-form-item-children {
        color: $ICON_SEARCH_INPUT;
      }
    }

    .ant-legacy-form-item-control {
      line-height: unset;
    }
    .sub_label {
      font-weight: normal;
      font-size: 10px;
      line-height: 23px;
      color: $BASE_BLACK_COLOR;
    }

    .modal_header {
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .description {
        font-weight: normal;
        font-size: 11px;
        line-height: 23px;
        color: $INPUT_MAIN_COLOR;
        margin-bottom: 0px;
      }
    }

    .anticon-plus, .anticon-minus {
      margin-left: 10px;
    }

    .ant-select {
      width: 100%;
      min-width: 163px;
      height: 40px;
      .ant-select-selector {
        height: 40px;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
        padding-top: 5px;
        min-width: 163px;
      }
    }

    .modal_content {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      align-items: normal;
      margin-bottom: 0px;

      .condition_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-legacy-form-item {
          flex: 1;
        }

        .order_amount {
          padding-left: 10px;
        }
      }

      .outcome_container {
        .outcome_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .logic {
            flex: 1;
            .ant-select-selection__rendered {
              max-width: 60px;
            }
          }
          .quantity {
            padding-left: 10px;
            .ant-select-selector {
              span {
                margin-bottom: 5px;
              }
            }
            .ant-select-selection__rendered {
              width: 80px;
            }
          }
          .product {
            padding-left: 10px;
            .ant-select-selector {
              p {
                margin-bottom: 5px;
              }
            }
            .ant-select-selection__rendered {
              width: 120px;
            }
            i {
              cursor: pointer;
              margin-left: 10px;
            }
          }
        }
      }

      .ruler_container {
        margin-bottom: 32px;

        .ruler_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-weight: normal;
            font-size: 13px;
            line-height: 23px;
            color: $BASE_BLACK_COLOR;
          }

          .ant-legacy-form-item {
            margin-bottom: 0px;

            .ant-legacy-form-item-control-wrapper {
              width: 165px;
            }
          }
        }
      }

      .limit_container {
        margin-bottom: 32px;

        .user_limit_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-weight: normal;
            font-size: 13px;
            line-height: 23px;
            color: $BASE_BLACK_COLOR;
          }

          .ant-legacy-form-item {
            margin-bottom: 0px;

            .ant-legacy-form-item-control-wrapper {
              width: 165px;
            }
          }
        }
      }

      .date_picker_wrapper {
        display: flex;
        justify-content: space-between;
        .ant-picker {
          height: 40px;
          background-color: $MAIN_BG_COLOR;
          border: none;
          border-radius: 4px;
          width: 160px;
          .ant-picker-input {
            .ant-picker-clear {
              svg {
                background-color: $MAIN_BG_COLOR;
              }
            }
          }
        }
      }
    }

    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .aditional_detail {
        display: flex;
        align-items: center;
        .ant-legacy-form-item {
          margin-bottom: 0px;
        }
        .ant-switch-checked {
          background-color: $BASE_GREEN_COLOR;
        }

        p {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-right: 19px;
          margin-top: 10px;
        }
      }

      .btn_wrapper button {
        min-width: 0px;
      }
      .btn_wrapper {
        display: flex;
        align-items: center;

        .close_btn {
          display: flex;
          height: 32px;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: none;
          font-size: 13px;
          background-color: $BASE_BLACK_COLOR;
          color: $BASE_WHITE_COLOR;
          font-family: 'Lato';

          svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
          }
        }
      }
    }
  }
}
