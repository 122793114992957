.restaurant_settings_layout {
  display: flex;
  width: 100%;
  margin-top: 50px;

  .delivery_setting {
    width: 281px;
    margin-right: 31px;
    padding-right: 29px;
    position: relative;

    .description {
      line-height: 1.5;
    }

    &::after {
      content: ' ';
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: $DIVIDER_BG_COLOR;
      right: 0;
      top: 0;
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: $DIVIDER_BG_COLOR;
      margin: 25px 0px;
    }

    .minutes,
    .days,
    .ambassadors,
    .factor {
      > div {
        display: flex;
        align-items: center;

        .ant-dropdown-trigger {
          width: 114px;
          margin-right: 16px;
        }

        p {
          margin-bottom: 0px;
        }
      }
    }
    .sar_code {
      margin-bottom: 20px;
    }
  }
  .restaurant_schedule {
    flex: 1;
  }
}
