.segments_view_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 0px 35px;

    .modal_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .name_wrapper {
        h3 {
          margin-bottom: 0px;
        }
      }
    }
  }
  .close_modal {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: $BASE_BLACK_COLOR;
    :hover {
      color: $BASE_LIGHT_GRAY_COLOR;
    }
  }
}

.new_segments_form_wrapper {
  display: flex;
  padding-top: 16px;
  .segments_input {
    border-bottom: 1px solid $DIVIDER_BG_COLOR;
    p {
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
  .ant-radio-wrapper {
    display: flex;
    margin-bottom: 8px;
    color: $BASE_DARK_GRAY_COLOR;
  }

  .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
    background-color: white;
    border-color: #ffc200;
  }
  .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner::after {
    border-color: #ffc200;
    background-color: #ffc200;
  }

  .ant-radio-wrapper + span {
    margin-left: 12px;
  }

  .sub_title {
    font-size: 18px;
    color: #b5b5b5;
  }
  input {
    height: 32px;
    width: 360px;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    font-size: 13px;
    border: none;
    font-family: 'Lato';
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }
  .select_modal {
    height: 32px;
    min-width: 150px;
    margin-right: 100px;
    .ant-select-selector {
      align-items: center;
      min-height: 32px;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Lato';
      border-radius: 4px;
    }
  }
  .side_btns,
  .side_btns_cat {
    margin-top: 16px;
    display: flex;
    justify-content: right;
    .aditional_detail {
      display: flex;
      align-items: center;
      .ant-form-item {
        margin-bottom: 0px;
      }
      .ant-switch-checked {
        background-color: $BASE_GREEN_COLOR;
      }

      p {
        font-weight: bold;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
        margin-right: 19px;
      }
    }
    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      line-height: 32px;
      .ant-btn-default:disabled {
        color: $BASE_WHITE_COLOR;
      }
      .cancel_btn_segments {
        color: $BASE_BLACK_COLOR;
        cursor: pointer;
        margin-right: 15px;
        :hover {
          color: $BASE_DARK_BLACK_COLOR;
        }
        margin-bottom: 0px;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 100px;
        background-color: $BASE_BLACK_COLOR;
        border: none;

        span,
        svg {
          color: $BASE_WHITE_COLOR;
          font-size: 13px;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .icon_upload_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: -10px;

    p {
      width: 120px;
      margin-right: 32px;
      font-size: 13px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 121px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      border: none;

      span,
      svg {
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        margin-right: auto;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .file_input {
      display: none;
    }
  }
  .csv-buttons {
    gap: 8px;
    display: flex;
  }

  .custom_button {
    cursor: pointer;
    width: 109px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    background-color: $FORM_ELEMENT_BG_COLOR;

    svg {
      width: 16px;
      height: 16px;
      font-size: 13px;
      margin-right: auto;
      color: $BASE_BLACK_COLOR;
    }
  }

  .user_dowload_button {
    cursor: pointer;
    width: 134px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    background-color: $FORM_ELEMENT_BG_COLOR;

    &:disabled {
      color:  $BASE_GRAY_COLOR;
      border-color: $BASE_LIGHT_GRAY_COLOR;

      img {
        filter: opacity(0.5)
      }
    }
  }

  .users_number {
    display: flex;
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
    margin-top: 10px;
  }
  .csv-info {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 40px;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: $BASE_BLACK_COLOR;
    margin-bottom: 15px;
  }

  .invalid_users {
    display: flex;
  }

  .invalid_user_value {
    margin: 0 8px 0 0 !important;
  }

  .download_button {
    cursor: pointer;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 2.5px 6px;
    border-radius: 4px;
    background-color: $SECONDARY_BG_COLOR;
  }
}
