.ambassador_monitor_layout {
  display: flex;
  flex-direction: column;
  min-width: 1124px;
  .ant-select-arrow {
    margin-top: 0px;
  }
  .content_header_layout {
    border-bottom: 1px solid $MAIN_BORDER_COLOR;
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }
  .ant-select-selection {
    height: auto;
    padding: 0;
    .ant-select-selection__rendered {
      padding: 0;
    }
  }
  .ambassador_monitor_wrapper {
    padding: 0px 45px;
    display: flex;
    height: calc(100vh - 130px);
    margin-top: 31px;

    .restaurant_filter_wrapper {
      width: 20%;
      padding: 16px 16px 0px 0px;
      margin-right: 26px;
      border-right: 1px solid $DIVIDER_BG_COLOR;
      > div {
        margin-bottom: 31px;
      }
      .title {
        margin-bottom: 17px;
      }
    }

    .ambassador_detail_wrapper {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;

      .profile_statistic_layout {
        margin: 0;
        border-top: none;
      }
      .global_map_title {
        margin: 31px;
      }
      .ambassador_map_layout {
        width: 100%;
      }
    }
  }
}
