.restaurant_map_layout {
  display: flex;
  width: 100%;

  input {
    height: 40px;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    font-size: 13px;
    border: none;
  }

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
    height: 40px;
    box-sizing: border-box;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    border-radius: 4px;
    border: none;
    padding: 8px 14px;
    outline: none;
    justify-content: space-between;
    cursor: pointer;
    font-size: 13px;
    font-weight: normal;
    line-height: 14px;

    svg {
      width: 18px;
    }
  }

  .map_setting {
    width: 357px;
    margin-right: 20px;
    .description {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
    }
    .geo_distance {
      margin-top: 27px;
      margin-bottom: 19px;
    }
  }

  .map_wrapper {
    position: relative;
    flex: 1;
    height: 300px;
    .search_btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: $BASE_WHITE_COLOR;
      right: 60px;
      top: 10px;
      z-index: 100;
      svg {
        width: 18px;
        height: 18px;
        color: $ICON_SEARCH_INPUT;
      }
    }
  }
}
