.ambassador_list_modal {
  .ant-modal {
    .ant-modal-body {
      padding: 40px 24px;
    }
  }

  .header_wrapper {
    width: 100%;
    margin-bottom: 29px;

    .title {
      font-size: 18px;
      color: #000000;
    }

    .ant-input-affix-wrapper {
      width: 186px;
      height: 32px;
      input {
        width: 186px;
        height: 32px;
        background: $FORM_ELEMENT_BG_COLOR;
        border-radius: 4px;
        border: none;
      }

      svg {
        width: 16px;
        height: 16px;
        color: #91929c;
      }
    }
  }

  .ambassador_list_wrapper {
    padding: 0 28px;

    .code {
      flex: 1;
    }
    .name {
      flex: 2;
    }
    .state,
    .average_time,
    .order_today {
      flex: 1.5;
    }

    .table_header {
      padding: 16px 9px;
      > div {
        font-size: 13px;
        font-weight: bold;
        color: #212935;
      }
    }

    .table_content {
      max-height: 400px;
      overflow: auto;
      .table_item {
        padding: 16px 9px;
        border-radius: 4px;

        > div {
          font-size: 12px;
          color: #212935;
        }

        .state {
          .status_circle {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 17px;
          }
        }

        &:nth-child(odd) {
          background-color: $FORM_ELEMENT_BG_COLOR;
        }
      }
    }
  }
}
