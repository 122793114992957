.ambassador_analysis_table_layout {
  .ant-select {
    width: 100%;
    height: 32px;
    .ant-select-selection {
      height: 100%;
      .ant-select-selection__rendered {
        margin: 0px;
        .ant-select-selection-selected-value {
          display: flex !important;
          align-items: center;
        }
      }
    }
  }
  .table_header {
    display: flex;
    padding: 12px 15px;
    color: $BASE_BLACK_COLOR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    .code {
      width: 16%;
      display: flex;
      align-items: center;
      text-align: left;
    }

    .name,
    .state {
      width: 25%;
      display: flex;
      align-items: center;
      text-align: left;
    }

    .delivery_average,
    .delivered_orders {
      width: 17%;
      text-align: center;
      justify-content: center;
      text-align: left;
    }
  }

  .table_content {
    height: calc(100vh - 280px);
    overflow: auto;
    .table_item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border-top: 1px solid $MAIN_BORDER_COLOR;

      .title {
        font-size: 13px;
        line-height: 18px;
        color: $BASE_BLACK_COLOR;
        font-weight: normal;
        margin-bottom: 0px;
      }
      .subtitle {
        font-size: 11px;
        line-height: 12px;
        font-weight: normal;
        color: $BASE_LIGHT_GRAY_COLOR;
      }
      .code {
        width: 16%;
        display: flex;
        align-items: center;
      }

      .name,
      .state {
        width: 25%;
        display: flex;
        align-items: center;
      }

      .delivery_average,
      .delivered_orders {
        width: 17%;
        text-align: center;
        justify-content: center;
      }
    }

    .table_item:nth-child(odd) {
      background: $TABLE_BG_COLOR;
    }
  }
}
