.ambassador_analysis_wrapper {
  width: 100%;
  display: flex;
  padding: 20px 45px;
  .ambassador_table_wrapper {
    width: 700px;
    min-width: 700px;
    margin-top: 50px;
  }
  .analysis_chart_wrapper {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
}
