.categories_filter_layout {
  width: 100%;
  padding: 20px 0px 10px 0px;
  display: flex;
  .ant-input-affix-wrapper:hover {
    border-color: transparent;
  }
  .ant-input-affix-wrapper {
    height: 32px;
    margin-left: auto;
    width: 159px;
    border-color: transparent;
    background-color: $MAIN_BG_COLOR;
    border-radius: 4px;

    input {
      border-radius: 4px;
      background-color: $MAIN_BG_COLOR;
      border: none;
      font-weight: normal;
      font-size: 13px;
      color: $INPUT_MAIN_COLOR;
      font-family: 'Lato';
    }

    .ant-input-suffix {
      svg {
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
  }

  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }

  .availability_by,
  .sort_by {
    height: 40px;
    min-width: 150px;
    margin-right: 10px;
    .ant-select-selector {
      height: 40px;
      align-items: center;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Lato';
      border-radius: 8px;
      color: black;
    }
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $BASE_WHITE_COLOR;
  }
  .apply_filters_btn {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 4px;
    background-color: $BASE_BLACK_COLOR;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: $BASE_WHITE_COLOR;
    padding: 9px 30px 9px 30px;

    svg {
      height: 15px;
      margin-left: 17px;
      color: $BASE_WHITE_COLOR;
    }
  }
}
