.promotion_view_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .modal_header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .last_import_time {
        font-weight: normal;
        font-size: 11px;
        line-height: 23px;
        color: $INPUT_MAIN_COLOR;
      }
    }

    .modal_content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 24px;
      margin-bottom: 35px;

      .promotion_photo {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 211px;
        height: 151px;
        margin-right: 33px;

        img {
          height: 100%;
        }
      }

      .detail {
        .type {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 19px;
        }
        .promotion_info {
          display: flex;
          align-items: center;

          img {
            min-width: 54px;
            height: 54px;
            border-radius: 10px;
            margin-right: 18px;
          }

          .description {
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: $BASE_BLACK_COLOR;
            margin-bottom: 0px;
          }
        }
      }
    }

    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .aditional_detail {
        p {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
        }
      }

      .btn_wrapper button {
        min-width: 0px;
      }
      .btn_wrapper {
        display: flex;
        align-items: center;

        .delete_btn,
        .edit_btn {
          width: 27px;
          height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background-color: $TABLE_ACTION_BTN_BG_COLOR;
          border: none;

          svg {
            width: 12px;
            height: 12px;
            color: $BASE_BLACK_COLOR;
          }
        }
        .delete_btn {
          margin-right: 10px;
        }
        .edit_btn {
          margin-right: 23px;
        }

        .close_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: none;
          font-size: 13px;

          svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
          }
        }

        .close_btn {
          height: 32px;
          background-color: $BASE_BLACK_COLOR;
          color: $BASE_WHITE_COLOR;
        }
      }
    }
  }
}
