.login_layout {
  display: flex;
  height: 100vh;
  background-image: url("../../../../../public/md_bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .ant-spin-nested-loading {
    width: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }

  .left_wrapper {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: $Auth_LEFT_WIDTH;
    height: 100%;
    background-color: $BASE_BLACK_COLOR;
    padding: 77px 25px 25px 25px;

    .login_wrapper {
      padding: 0 16px;
      padding-top: 12px;

      .title {
        color: $BASE_WHITE_COLOR;
        font-size: 23px;
        font-weight: normal;
        line-height: 35px;
        letter-spacing: -0.220106px;
      }

      .description {
        font-size: 14px;
        line-height: 25px;
        font-weight: normal;
        color: $BASE_GRAY_COLOR;
        margin-bottom: 26px;
      }

      .ant-legacy-form-item {
        margin-bottom: 16px;

        .ant-input-affix-wrapper {
          position: relative;
          border-color: transparent;
          background-color: $INPUT_SECONDARY_BG;
          border-radius: 4px;
        }
        .ant-input-affix-wrapper-focused {
          border-width: 1px;
          border-color: $INPUT_FOCUSED;
        }
        .has-error {
          .ant-input-affix-wrapper {
            border-width: 1px;
            border-color: $INPUT_ERROR;
          }
        }
        .ant-legacy-form-explain {
          color: $INPUT_ERROR;
        }

        input {
          position: absolute;
          width: 100%;
          height: 30px;
          top: 0;
          left: 0;
          background-color: transparent;
          color: $USER_EDIT_HEADER_TEXT_COLOR;
          border: none;
          font-weight: normal;
          font-size: 13px;
          font-family: "Lato";
          border-radius: 3px;
          padding-left: 39px;
          padding-right: 10px;
        }
        input::selection {
          color: #fff;
          background-color: #1890ff;
        }
        ::placeholder {
          color: $BASE_DARK_GRAY_COLOR;
        }
        .ant-input-prefix {
          z-index: 1;
          svg {
            width: 15px;
            height: 15px;
            color: $BASE_WARM_GRAY_COLOR;
            background-color: transparent;
          }
        }
      }

      .error_msg {
        margin-bottom: 10px;
        max-width: 310px;
        word-wrap: normal;
      }

      .login-form-btn {
        .ant-legacy-form-item-children {
          display: flex;
          justify-content: flex-end;
        }

        button {
          height: 32px;
          background-color: $BASE_YELLOW_COLOR;
          border-radius: 5px;
          color: $BASE_WHITE_COLOR;
          border: none;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-family: "Lato";

          svg {
            width: 15px;
            height: 15px;
            margin-left: 5px;
          }
        }
      }
    }

    .option {
      margin-bottom: 16px;
      margin-top: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8d8d8d;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: -0.13px;
    }

    .sso_btn {
      width: 100%;
      display: flex;
      padding: 8px 0px;
      align-items: center;
      height: 32px;
      background-color: $BASE_BLACK_COLOR;
      border-radius: 4px;
      color: $BASE_WHITE_COLOR;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 32px;

      svg {
        width: 16px;
        height: 16px;
        margin-left: 2px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .sso_search_input {
      .ant-input {
        border-color: transparent;
        background-color: $INPUT_SECONDARY_BG;
        color: $USER_EDIT_HEADER_TEXT_COLOR;
        font-size: 13px;
        font-family: "Lato";
      }
      ::placeholder {
        color: $BASE_DARK_GRAY_COLOR;
      }
      .ant-input-affix-wrapper {
        background-color: $INPUT_SECONDARY_BG;
        z-index: 1;
        border-color: transparent;

        .ant-input-prefix {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 12px;
        }
        svg {
          width: 15px;
          height: 15px;
          color: $BASE_WARM_GRAY_COLOR;
          background-color: transparent;
        }
      }

      .ant-input-search-button {
        background-color: $BASE_YELLOW_COLOR;
        color: #fff;
      }

      .ant-input-group-addon:last-child {
        inset-inline-start: 0;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .divider {
        width: 59px;
        height: 1px;
        background-color: $DIVIDER_COLOR;
        margin-bottom: 20px;
      }
      .forgot_password {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.4px;
        color: $BASE_WHITE_COLOR;
      }

      .copy_right {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $COPY_RIGHT_COLOR;
        margin-top: 55px;
        margin-bottom: 0px;
      }
    }
  }
}
