.ambassador_map_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 27px 24px 14px 24px;

    .ambassador_map_layout {
      position: relative;
      width: 100%;
      height: 486px;
    }

    .modal_footer {
      display: flex;
      justify-content: flex-end;

      button {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        background-color: $BASE_BLACK_COLOR;
        border: none;
        color: $BASE_WHITE_COLOR;
        font-size: 13px;
        margin-top: 18px;

        svg {
          width: 16px;
          height: 16px;
          margin-left: 16px;
        }
      }
    }
  }
}
