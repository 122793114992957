.delivery_addresses_layout {
  padding: 38px 21px;

  .header_title {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: $BASE_BLACK_COLOR;
    margin-bottom: 30px;
  }
  .table_content {
    max-height: 500px;
    overflow: auto;
    .delivery_address {
      width: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: flex-start;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
      .address1 {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: $BASE_BLACK_COLOR;
      }
      .address2 {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 18px;
        color: $BASE_LIGHT_GRAY_COLOR;
      }
      .product {
        width: 94px;
        border-radius: 4px;
        padding: 4px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: $BASE_BLACK_COLOR;
        background-color: $TABLE_DEVICE_BG_COLOR;
        margin-top: 11px;
      }
    }
  }
}
