.overview_status_card {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: $MAIN_BG_COLOR;
  border-radius: 4px;
  height: 147px;
  padding: 20px 0px;

  &:not(:last-child) {
    margin-right: 40px;
  }

  p {
    font-weight: 300;
    font-size: 48px;
    line-height: 56px;
    margin-top: 14px;
    color: $BASE_BLACK_COLOR;
  }

  span {
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: $BASE_DARK_GRAY_COLOR;
    margin-top: auto;
  }
}
