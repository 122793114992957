.distribution_edit_layout {
  display: flex;
  flex-direction: column;
  min-width: 1124px;

  .content_header_layout {
    border-bottom: 1px solid $DIVIDER_BG_COLOR;
    .user_info {
      font-family: 'Lato';
      font-size: 13px;
      color: $USER_EDIT_HEADER_TEXT_COLOR;
    }
  }
  input {
    border-radius: 4px;
    background-color: $MAIN_BG_COLOR;
    border: none;
    font-weight: normal;
    font-size: 13px;
    color: $INPUT_MAIN_COLOR;
  }
  .ant-input-affix-wrapper {
    height: fit-content;
    width: 100%;
    margin-left: auto;
    border: none;
    border-color: transparent;
    background-color: $MAIN_BG_COLOR;
    border-radius: 4px;

    input {
      border-radius: 4px;
      background-color: $MAIN_BG_COLOR;
      border: none;
      font-weight: normal;
      font-size: 13px;
      color: $INPUT_MAIN_COLOR;
      height: 24px;
      font-family: 'Lato';
    }

    .ant-input-suffix {
      svg {
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
  }

  .list {
    margin-top: 36px;
    .item {
      margin-bottom: 23px;
      .title {
        margin-bottom: 0px;
      }
      .description {
        margin-top: 9px;
        margin-bottom: 0px;
      }
      .restaurant-info {
        padding-right: 10px;
      }
      .sector-restaurant-info {
        padding-left: 10px;
      }
    }
  }
  .card_header {
    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: $BASE_BLACK_COLOR;
    }
  }
  .card_list {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    margin-top: 26px;

    .list {
      overflow-y: auto;
    }
  }
  .sector_wrapper {
    padding: 0px 45px;
    display: flex;
    height: calc(100vh - 250px);

    .sector_name_wrapper {
      width: 30%;
      padding: 16px 16px 0px 0px;
      margin-right: 26px;
      border-right: 1px solid $DIVIDER_BG_COLOR;
      .card_list {
        .description {
          line-height: 1.5;
        }
        .sector_input_wrapper {
          margin-top: 14px;
          .sector_label {
            font-size: 12px;
            font-weight: bold;
            color: $BASE_BLACK_COLOR;
            margin-bottom: 14px;
          }
          .ant-input {
            height: 32px;
            font-family: 'Lato';
          }
        }
      }
    }
    .availabe_restaurant {
      width: 35%;
      padding: 16px 16px 0px 0px;
      margin-right: 26px;
      border-right: 1px solid $DIVIDER_BG_COLOR;
    }

    .empty_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
