.ant-form-item {
  margin-bottom: 20px;
  .title_deals {
    margin-bottom: 14px;
    margin-top: 0;
  }

  .title_weight {
    margin-top: 36px;
  }

  .description_weight {
    color: #7b7b7b;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Lato';
    line-height: 14.4px;
  }
}

.extend_tabs {
  margin-bottom: 16px;
  padding-right: 0;

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      font-family: 'Lato';
      .ant-tabs-tab-btn {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #6b7280;
        font-family: 'Lato';
        display: flex;
        align-items: center;
        justify-content: center;

          .dot{
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ef4444;
            margin-left: 4px;
          }
      }
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #212121;
        font-family: 'Lato';
        text-shadow: inherit;
        display: flex;
        align-items: center;
        justify-content: center;

          .dot{
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ef4444;
            margin-left: 4px;
          }
      }
    }
    .ant-tabs-ink-bar {
      background: black;
      height: 1px;
    }
  }
}

.image_tabs {
  margin-bottom: 0;
  padding-right: 0;
  margin-top: -12px;

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      font-family: 'Lato';
      .ant-tabs-tab-btn {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #6b7280;
        font-family: 'Lato';
        display: flex;
        align-items: center;
        justify-content: center;

          .dot{
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ef4444;
            margin-left: 4px;
          }
      }
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #212121;
        font-family: 'Lato';
        text-shadow: inherit;
        display: flex;
        align-items: center;
        justify-content: center;

          .dot{
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ef4444;
            margin-left: 4px;
          }
      }
    }
    .ant-tabs-ink-bar {
      background: black;
      height: 1px;
    }
  }
}

.tabs_input {
  .qr_code_text {
    margin-left: 5px;
    font-size: 12px;
  }
  .upload-btn {
    display: flex;
    align-items: center;
    margin-top: 15px;

    p {
      width: 109px;
      margin-right: 32px;
      font-size: 13px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 109px;
      background-color: #edf0f2;
      border: none;

      span,
      svg {
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        font-family: 'Lato';
        font-weight: normal;
        margin-right: 6px;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  p {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  input {
    height: 32px;
    width: 360px;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    font-size: 13px;
    border: none;
    font-family: "Lato";
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }
  
  .select_modal {
    min-width: 150px;

    .ant-select-selector {
      align-items: center;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: "Lato";
      border-radius: 4px;
    }
  }

  .has-error {
    border: 1px solid red;
  }

  .has-error_area {
    border: 1px solid red;
  }

  .ant-radio-wrapper {
    display: flex;
    margin-bottom: 8px;
    color: $BASE_BLACK_COLOR;
  }

  .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
    background-color: white;
    border-color: #ffc200;
  }
  .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner::after {
    border-color: #ffc200;
    background-color: #ffc200;
  }

  .ant-radio-wrapper + span {
    margin-left: 12px;
  }

  .sub_title {
    font-size: 18px;
    color: #b5b5b5;
  }
  .ant-checkbox-inner {
    border-radius: 2px;
    border-color: black;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #ffc200 !important;
    color: #ffc200;
  }
  .ant-checkbox-inner::after {
    background-color: #ffc200;
    border-radius: 2px;
  }
  .repeatableInputs > div {
    margin-bottom: 10px;
  }
  .lan_items span {
    display: inline-block;
  }

  .lan_items {
    display: flex;
    align-items: center;
    gap: 32px;
    border-bottom: 1.5px solid #edf0f2;
    margin-top: 20px;

    .title_deals {
      margin-top: 0;
    }

    span {
      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #6b7280;
        display: flex;
        padding-bottom: 0px;
        text-decoration: none;
        cursor: pointer;
      }
      .lang_dot {
        span{
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ef4444;
          margin-left: 4px;
        }
      }
    }

    .tab_active {
      display: flex;
      width: 100%;
      color: black;
      padding-bottom: 20px;
    }
  }
  .line_divider {
    margin-top: 18px;
    border-bottom: 1.5px solid #edf0f2;
  }
  .input_group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    
    .input-item {
      flex: 1;
    }
  }
  // .checkbox-container {
  //   height: 305px;
  //   overflow-y: auto;
  // }
  .aditional_detail {
    display: flex;
    align-items: center;
    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-switch-checked {
      background-color: $BASE_GREEN_COLOR;
    }
    .ant-switch.ant-switch-checked {
      background-color: $BASE_GREEN_COLOR;
    }

    p {
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      color: $BASE_BLACK_COLOR;
      margin-right: 12px;
      margin-bottom: 8px;
    }
  }
  .statistics {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;

    .stat_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;
    }

    .stat_wrapper:last-child {
      margin-bottom: 0;
    }

    .stat_title {
      font-weight: bold;
      font-size: 13px;
      line-height: 14px;
      color: $BASE_BLACK_COLOR;
      margin-right: 12px;
      margin-bottom: 8px;
      margin-top: 0;
    }
    .stat_desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $BASE_BLACK_COLOR;
      margin-right: 12px;
      margin-bottom: 0;
      margin-top: 0;
    }
    .stat_num {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $BASE_BLACK_COLOR;
      margin-top: 0;
    }
  }
}

.new_offers_form_wrapper input {
  margin-bottom: 0;
}

.start_date {
  height: 40px;
  min-width: 169px;
  background-color: $MAIN_BG_COLOR;
  border: none;
  border-radius: 4px;
  .ant-picker-input {
    .ant-picker-clear {
      svg {
        background-color: $MAIN_BG_COLOR;
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
    svg {
      background-color: $MAIN_BG_COLOR;
      width: 15px;
      height: 15px;
      color: $INPUT_MAIN_COLOR;
    }
  }
}

.repeatTime {
  height: 40px;
  min-width: 100%;
  background-color: $FORM_ELEMENT_BG_COLOR;
  border: none;
  border-radius: 4px;
  .ant-picker-input {
    .ant-picker-clear {
      svg {
        background-color: $MAIN_BG_COLOR;
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
    svg {
      background-color: $MAIN_BG_COLOR;
      width: 15px;
      height: 15px;
      color: $INPUT_MAIN_COLOR;
    }
  }
}

.two_line {
  margin-bottom: 20px;
  display: flex;
  gap: 12px;

  .has-error_area {
    border: 1px solid red;
  }
}

.imageContainer {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  .dropzone {
    width: 165px;
    height: 118px;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #edf0f2;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .add-photo-icon {
      font-size: 60px;
      color: #c1c1c1;
    }

    .delete-button {
      position: absolute;
      bottom: 9px;
      right: 9px;
      z-index: 1;
      background-color: white;
      border: none;
      padding: 2px 5px;
    }
  }

  .delete-btn {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 27px;
      height: 21px;
      background-color: white;
      border: none;
      border-radius: 4px;

      span,
      svg {
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        margin-right: auto;
      }
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  .container_valid {
    margin-left: 12px;
    width: 173px;
    .image_valid {
      font-family: "Lato";
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0px;
    }
    .upload-btn {
      display: flex;
      align-items: center;

      p {
        width: 109px;
        margin-right: 32px;
        font-size: 13px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 109px;
        background-color: #edf0f2;
        border: none;
        margin-top: 10px;

        span,
        svg {
          color: $BASE_BLACK_COLOR;
          font-size: 13px;
          font-family: 'Lato';
          font-weight: normal;
          margin-right: auto;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.new_filters_form_wrapper {
  .upload-btn {
    display: flex;
    align-items: center;

    p {
      width: 109px;
      margin-right: 32px;
      font-size: 13px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 109px;
      background-color: #edf0f2;
      border: none;

      span,
      svg {
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        margin-right: 6px;
        font-family: 'Lato';
        font-weight: normal;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .filters_input {
    p {
      margin-bottom: 14px;
      margin-top: 10px;
    }
    .has-error {
      border: 1px solid red;
      height: 34px;
    }
  }
  .ant-radio-wrapper {
    display: flex;
    margin-bottom: 8px;
    color: $BASE_DARK_GRAY_COLOR;
  }

  .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
    background-color: white;
    border-color: #ffc200;
  }
  .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner::after {
    border-color: #ffc200;
    background-color: #ffc200;
  }

  .ant-radio-wrapper + span {
    margin-left: 12px;
  }

  .sub_title {
    font-size: 18px;
    color: #b5b5b5;
  }
  input {
    height: 32px;
    width: 100%;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    font-size: 13px;
    border: none;
    font-family: "Lato";
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }
  .select_modal {
    height: 32px;
    width: 250px;
    // margin-right: 100px;
    .ant-select-selector {
      align-items: center;
      min-height: 32px;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: "Lato";
      border-radius: 4px;
    }
  }
  .side_btns,
  .side_btns_cat {
    margin-top: 16px;
    display: flex;
    justify-content: right;
    .aditional_detail {
      display: flex;
      align-items: center;
      .ant-form-item {
        margin-bottom: 0px;
      }
      .ant-switch-checked {
        background-color: $BASE_GREEN_COLOR;
      }

      p {
        font-weight: bold;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
        margin-right: 19px;
      }
    }
    .btn_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      line-height: 32px;
      .ant-btn-default:disabled {
        color: $BASE_WHITE_COLOR;
      }
      .cancel_btn_segments {
        color: $BASE_BLACK_COLOR;
        cursor: pointer;
        margin-right: 15px;
        :hover {
          color: $BASE_DARK_BLACK_COLOR;
        }
        margin-bottom: 5px;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 100px;
        background-color: $BASE_BLACK_COLOR;
        border: none;

        span,
        svg {
          color: $BASE_WHITE_COLOR;
          font-size: 13px;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .icon_upload_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: -10px;

    p {
      width: 120px;
      margin-right: 32px;
      font-size: 13px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 121px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      border: none;

      span,
      svg {
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        margin-right: auto;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .file_input {
      display: none;
    }
  }
  .csv-buttons {
    gap: 8px;
    display: flex;
  }
  .custom-file-upload {
    display: flex;
    padding: 8px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    width: 109px;
    font-size: 13px;
    height: 32px;
    cursor: pointer;
    svg {
      color: $BASE_BLACK_COLOR;
      font-size: 13px;
      margin-right: auto;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .custom-file-refresh {
    display: flex;
    padding: 8px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    width: 109px;
    font-size: 13px;
    height: 32px;
    cursor: pointer;
    svg {
      color: $BASE_BLACK_COLOR;
      font-size: 13px;
      margin-right: auto;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .users_number {
    display: flex;
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
    margin-top: 10px;
  }
  .csv-info {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 40px;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: $BASE_BLACK_COLOR;
    margin-bottom: 15px;
  }
}

.location_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: $ICON_SEARCH_INPUT;
  box-sizing: border-box;
  padding: 30px 45px 20px 40px;
  min-width: 1124px;
  font-family: "Lato";

  .ant-input-affix-wrapper {
    width: 186px;
    height: fit-content;
    border-color: transparent;
    background-color: $MAIN_BG_COLOR;
    border-radius: 4px;

    input {
      border-radius: 4px;
      background-color: $MAIN_BG_COLOR;
      border: none;
      font-weight: normal;
      font-size: 13px;
      color: $INPUT_MAIN_COLOR;
      font-family: "Lato";
    }

    .ant-input-suffix {
      svg {
        width: 15px;
        height: 15px;
        color: $INPUT_MAIN_COLOR;
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 4px;
    padding: 0 26px;

    span {
      color: $BASE_WHITE_COLOR;
      font-weight: normal;
      font-size: 13px;
      line-height: 14px;
    }

    svg,
    img {
      width: 16px;
      height: 16px;
      margin-left: 12px;
      color: $BASE_WHITE_COLOR;
    }
  }
  .loc_btn_wrapper {
    display: flex;
    .ant-btn-default:hover {
      color: $BASE_WHITE_COLOR;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 169px;
      background-color: $BASE_YELLOW_COLOR;
      color: $BASE_WHITE_COLOR;
      border: none;
      border-radius: 4px;
      font-family: "Lato";
      font-size: 13px;

      span,
      svg {
        color: $BASE_WHITE_COLOR;
        font-size: 13px;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .new_restaurant_btn,
    .new_delivery_btn {
      margin-right: 19px;
    }

    .delivery_zones_btn {
      min-width: unset;
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 18px;
      svg {
        margin-left: 0px;
      }
    }
  }
}
