.analytical_sidebar{
  width: 271px;
  height: 100%;
  box-shadow: 0 0 30px rgba(0,0,0,.08);
  z-index: 5;
  .analytical_sidebar_title{
    padding: 40px 51px 0 46px;
    p{
      font-size: 18px;
      color:black;
      margin-bottom: 5px;
    }
    font-size: 9px;
    line-height: 20px;
    font-weight: 400;
    color: $BASE_LIGHT_GRAY_COLOR;
    p{
      span{
        font-size: 18px;
        color:black;
      }
    }
  }
  hr{
    width: 196px;
    margin-bottom: 26px;
    color: #f3f3f3;
  }
  nav{
    width: 100%;
    a{
      display: flex;
      font-size: 13px;
      line-height: 15.6px;
      font-weight: 400;
      color: #747686;
      padding: 12px 51px 12px 46px;
      text-decoration: none;
    }
  }
  .active{
    display: flex;
    width: 100%;
    background: #F2F2F4;
    color: black;
  }
}