.promo_config_modal {
    .ant-modal-content {
      background: $BASE_WHITE_COLOR;
      box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
      padding: 40px 40px 32px 40px;
  
      .modal_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 1px solid $DIVIDER_BG_COLOR;
  
        .name_wrapper {
          h3 {
            margin-bottom: 0px;
            margin-right: 5px;
          }
  
          .name_wrapper_id {
            padding-top: 5px;
            color: #7B7B7B;
            font-size: 12px;
            font-weight: 400;
            font-family: 'Lato';
            line-height: 14.4px;
          }
        }
      }
    }
    .close_modal {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        color: $BASE_BLACK_COLOR;
        :hover {
          color: $BASE_LIGHT_GRAY_COLOR;
        }
      }
    .promo_form_wrapper {
        margin-top: 32px;
    }

    .inputs_wrapper {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
      }
      
      .inputs_wrapper.expanded {
        max-height: 500px;
      }

    .aditional_detail {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .ant-form-item {
          margin-bottom: 0px;
        }
        .ant-switch-checked {
          background-color: $BASE_GREEN_COLOR;
        }
        .ant-switch.ant-switch-checked:hover {
            background-color: #7ed321;
        }
    
        p {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-left: 12px;
          margin-bottom: 0;
        }
    }

    .two_line {
        margin-bottom: 20px;
        display: flex;
        gap: 12px;
      
        .date_wrapper {
            flex: 1;
        }
        .date_input {
            height: 30px;
            width: 100%;
            background-color: $FORM_ELEMENT_BG_COLOR;
            border: none;
            border-radius: 4px;
            .ant-picker-input {
              .ant-picker-clear {
                svg {
                  background-color: $MAIN_BG_COLOR;
                  width: 15px;
                  height: 15px;
                  color: $INPUT_MAIN_COLOR;
                }
              }
              svg {
                background-color: $MAIN_BG_COLOR;
                width: 15px;
                height: 15px;
                color: $INPUT_MAIN_COLOR;
              }
            }
        }
        .has-error_area {
            border: 1px solid red;
          }
    }

    .amount_promo {
        height: 32px;
        border-radius: 4px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        border: none;
        font-family: "Lato";
    }

    .amount_promo_error {
        height: 32px;
        border-radius: 4px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        border: none;
        font-family: "Lato";
        border: 1px solid red;
    }

    .checkbox_wrapper {
        display: flex;
        margin-top: 20px;
        .ant-checkbox-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
    
            .ant-checkbox-inner {
                border-radius: 2px;
                border-color: black;
              }
            
              .ant-checkbox-checked .ant-checkbox-inner {
                border-color: #ffc200 !important;
                color: #ffc200;
                background-color: $BASE_WHITE_COLOR;
              }
              .ant-checkbox-inner::after {
                border-radius: 2px;
                background-color: $BASE_YELLOW_COLOR;
                width: 8px;
                height: 8px;
                border: none;
                left: 50%;
                top: 50%;
                transform: rotate(90deg) scale(1) translate(-50%,50%);
              }
          }
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid $DIVIDER_BG_COLOR;
        padding-top: 16px;
        margin-top: 20px;
  
        .btn_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
  
          p {
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            color: $BASE_BLACK_COLOR;
            cursor: pointer;
            margin-right: 12px;
            margin-bottom: 0;
          }
  
          .save_button {
            min-width: 146px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: none;
            font-size: 13px;
            background-color: $BASE_BLACK_COLOR;
            color: $BASE_WHITE_COLOR;
            svg {
                width: 16px;
                height: 16px;
                margin-left: 4px;
              }
          }
        }
      }
  }
  