.ambassador_edit_layout {
  .ambassador_edit_wrapper {
    display: flex;
    flex-direction: column;
    min-width: 1124px;
    padding: 0px 21px;

    .ant-select-open {
      .ant-select-selection-item {
        color: black;
      }
    }

    .profile_wrapper {
      padding: 31px 0px 34px 0px;
      border-top: 1px solid $MAIN_BORDER_COLOR;
      border-bottom: 1px solid $MAIN_BORDER_COLOR;

      .profile_info {
        display: flex;
        align-items: center;
        margin-top: 31px;

        .description {
          margin-bottom: 0px;
          min-height: 22px;
        }

        .avatar {
          width: 49px;
          height: 49px;
          border-radius: 50%;
          background-color: $USER_AVATAR_BG_COLOR;
          object-fit: cover;
          margin-right: 24px;
        }
        
        .title {
          margin-bottom: 12px;
        }

        .info:not(:last-child) {
          margin-right: 108px;
        }
      }
    }

    .profile_setting_wrapper {
      display: flex;
      .week_assignment {
        flex: 3;
        border-right: 1px solid $MAIN_BORDER_COLOR;
        padding-top: 38px;
        .week_assignment_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 20px;
          button {
            width: 27px;
            height: 21px;
            display: flex;
            justify-content: center;
            border-radius: 4px;
            background-color: $TABLE_ACTION_BTN_BG_COLOR;
            border: none;

            svg {
              width: 12px;
              height: 12px;
            }
          }
        }

        .card {
          margin-bottom: 21px;

          .schedule_per_day {
            display: flex;
            border-bottom: 1px solid $MAIN_BORDER_COLOR;

            .ant-select {
              height: 40px;
              min-width: 160px;
              margin-bottom: 14px;
              .ant-select-selector {
                height: 40px;
                min-width: 160px;
                align-items: center;
                border: none;
                background-color: $FORM_ELEMENT_BG_COLOR;
                font-weight: normal;
                font-size: 13px;
                border-radius: 8px;
              }
            }

            .divider {
              width: 1px;
              height: auto;
              background-color: $MAIN_BORDER_COLOR;
              margin: 0px 18px;
            }

            .time_slot_wrapper {
              display: flex;
              justify-content: center;
              flex: 1;
              flex-direction: column;

              .time_slot {
                display: flex;

                .ant-select:not(:last-child) {
                  margin-right: 11px;
                }
                .restaurant_menu {
                  flex: 1;
                }
              }
            }
          }
        }
      }
      .other_detail {
        flex: 2;
        padding-left: 33px;
        padding-top: 38px;
        .other_settings {
          margin-top: 47px;
          margin-bottom: 190px;
          input {
            height: 40px;
            border-radius: 4px;
            background-color: $FORM_ELEMENT_BG_COLOR;
            color: $BASE_BLACK_COLOR;
            font-size: 13px;
            border: none;
          }
          .change_password_title {
            font-size: 18px;
            margin-bottom: 49px;
          }
          .password_label {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
