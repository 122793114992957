.restaurants_new_layout {
  .main_content {
    padding: 0px 39px;

    .restaurants_info_wrapper {
      display: flex;
      flex-direction: column;
      min-width: 1124px;
      border-top: solid 2px $SECONDARY_BG_COLOR;

      input {
        height: 40px;
        border-radius: 4px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        border: none;
      }

      .ant-dropdown-trigger {
        display: flex;
        align-items: center;
        height: 40px;
        box-sizing: border-box;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $BASE_BLACK_COLOR;
        border-radius: 4px;
        border: none;
        padding: 8px 14px;
        outline: none;
        justify-content: space-between;
        cursor: pointer;
        font-size: 13px;
        font-weight: normal;
        line-height: 14px;

        svg {
          width: 18px;
          color: $INPUT_MAIN_COLOR;
        }
      }

      h3 {
        margin-top: 31px;
      }

      .base_info_wrapper {
        .ant-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          p {
            height: 40px;
          }
          input {
            margin-top: -10px;
            font-family: 'Lato';
          }

          .ant-dropdown-trigger {
            margin-top: -10px;
          }

          .ant-form-item {
            flex: 1 1 30%;
            padding-right: 10px;
            margin-top: 10px;
            margin-bottom: 35px;
            .has-error {
              .ant-input {
                background-color: $FORM_ELEMENT_BG_COLOR;
              }
              .ant-form-explain {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
