.deals_header_layout {
  box-sizing: border-box;
  padding: 0px 40px;
  padding-right: 44px;
  min-width: 1124px;
  .header_title {
    padding-top: 30px;
    font-weight: normal;
    font-size: 19px;
    line-height: 22px;
    color: $BASE_BLACK_COLOR;
  }
  .deals_config_header_wrapper {
    width: 100%;
    height: 129px;
    border-bottom: 1px solid #edf0f2;
  }
}

.btn_wrapper:hover {
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $BASE_WHITE_COLOR;
  }
}
.btn_wrapper {
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 169px;
    background-color: $BASE_YELLOW_COLOR;
    color: $BASE_WHITE_COLOR;
    border: none;
    border-radius: 4px;
    font-family: 'Lato';
    font-size: 13px;

    span,
    svg {
      color: $BASE_WHITE_COLOR;
      font-size: 13px;
    }
    svg {
      width: 13px;
      height: 13px;
    }
  }
}

.menu_items nav {
  display: inline-block;
}

.menu_items {
  display: flex;
  align-items: center;
  gap: 32px;
  padding-top: 30px;

  nav {
    a {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #6b7280;
      display: flex;
      border-bottom: 0px solid #edf0f2;
      padding-bottom: 25px;
      text-decoration: none;
    }
  }
  .active {
    display: flex;
    width: 100%;
    color: black;
    border-bottom: 1.5px solid black;
    padding-bottom: 25px;
  }
}

.analytical_layout_content {
  .analytical_layout_content_box {
  }
}
