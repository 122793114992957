.day_analysis_wrapper {
  width: 100%;
  display: flex;
  padding: 0px 45px 20px;
  h5 {
    font-size: 14px;
    font-weight: bold;
    color: #212935;
    margin-bottom: 10px;
  }
  .title {
    font-size: 12px;
    font-weight: normal;
    color: #212935;
  }
  .card_item {
    position: relative;
    padding: 5px;
    background: $FORM_ELEMENT_BG_COLOR;
    border-radius: 4px;
    margin-top: 14px;
    cursor: pointer;

    &::before {
      content: ' ';
      position: absolute;
      width: 2px;
      height: calc(100% - 10px);
      background: #7ed321;
      left: 5px;
      top: 5px;
    }
    .count_number {
      font-size: 40px;
      color: #687992;
      margin-left: 27px;
      margin-bottom: 15px;
      margin-top: 7px;
    }
    .card_description {
      font-size: 12px;
      color: #212935;
      margin-top: 6px;
      margin-left: 27px;
    }
    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  .card_row {
    display: flex;
    align-items: center;
    .card_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 188px;
    }

    .production_card {
      flex: 1;
      &::before {
        background: #50e2c2;
      }
      .count_number {
        color: #50e2c2;
      }
    }
    .solving_card {
      flex: 1;
      background: #fceded;

      &::before {
        background: #d35858;
      }
      .count_number {
        color: #d35858;
      }
    }
    .sloping_card {
      flex: 1;
      &::before {
        background: #5a9d8e;
      }
      .count_number {
        color: #5a9d8e;
      }
    }
    .delivered_card {
      flex: 1;
      background: #eff4ea;

      &::before {
        background: #8dd73c;
      }
      .count_number {
        color: #8dd73c;
      }
    }
    .ambassadors_card {
      flex: 1;
      &::before {
        background: #41635b;
      }
      .count_number {
        color: #41635b;
      }
    }
  }

  .latest_statistic_wrapper {
    flex: 1;
    .average_delivery_time {
      padding: 22px 20px 42px 0px;
      border-bottom: 1px solid #edf0f2;
      .received_card {
        min-height: 113px;
        background-color: #7ed321;
        .count_number,
        .card_description {
          color: white;
        }
      }
    }
    .real_time_order {
      padding-top: 32px;
      padding-right: 20px;
    }
  }
  .total_statistic_wrapper {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 22px 0px 0px 20px;
    border-left: 1px solid #edf0f2;
    .real_time_order {
      display: flex;
      flex-direction: column;
      .card_row {
        flex: 1;
        .card_item {
          height: 100%;
        }
        &:not(:last-child) {
          margin-bottom: 60px;
        }
      }
    }
  }
}
