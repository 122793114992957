.restaurant_analysis_layout {
  .analysis_content_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 900px;
    padding: 13px 30px;
    border-bottom: 1px solid #edf0f2;

    .title_wrapper {
      .title {
        font-size: 18px;
        color: $BASE_BLACK_COLOR;
        margin-bottom: 15px;
      }
      .subtitle {
        font-size: 12px;
        font-weight: bold;
        color: $USER_EDIT_HEADER_TEXT_COLOR;
        margin-bottom: 0px;
      }
    }

    .tab_wrapper {
      display: flex;
      align-items: center;

      .ant-select-open {
        .ant-select-selection-item {
          color: black;
        }
      }

      .ant-select {
        min-width: 195px;
        height: fit-content;
        .ant-select-selector {
          height: 40px;
          align-items: center;
          border: none;
          background-color: $FORM_ELEMENT_BG_COLOR;
          font-weight: normal;
          font-size: 13px;
          font-family: 'Lato';
          border-radius: 8px;
          .ant-select-selection__rendered {
            margin: 0px;
          }
        }
      }
      .divider {
        width: 1px;
        height: 64px;
        background-color: #ecf0f2;
        margin: 0 20px;
      }
      .tabs {
        display: flex;
        align-items: center;

        .tab_item {
          min-width: 158px;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 14px;
          font-size: 12px;
          color: #687992;
          cursor: pointer;

          img {
            margin-right: 10px;
          }
          &:not(:last-child) {
            margin-right: 14px;
          }
        }
        .tab-active {
          background: $FORM_ELEMENT_BG_COLOR;
          border-radius: 8px;
        }
      }
    }
  }

  .analysis_content_wrapper {
    display: flex;
    flex-direction: column;
  }
}
