.location_checkbox_name { 
    font-size: 12px;
    font-weight: 400;
    font-family: 'Lato';
    color: $BASE_BLACK_COLOR;
}

.location_checkbox_id {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Lato';
    color: $BASE_LIGHT_GRAY_COLOR;
}