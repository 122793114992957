.report_table_layout {
  width: 100%;
  .table_header {
    display: flex;
    padding: 12px 15px;
    color: $BASE_BLACK_COLOR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    .userid {
      width: 10%;
      display: flex;
      align-items: center;
    }

    .name {
      width: 80%;
      display: flex;
      align-items: center;
    }
    .actions {
      width: 10%;
      text-align: center;
      justify-content: center;
    }
  }

  .table_content {
    height: calc(100vh - 300px);
    overflow: auto;
    .table_item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border-top: 1px solid $MAIN_BORDER_COLOR;
      .name {
        width: 80%;
        display: flex;
        align-items: center;
      }

      .actions {
        width: 10%;
        display: flex;
        justify-content: center;

        button {
          width: 27px;
          height: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background-color: $TABLE_ACTION_BTN_BG_COLOR;
          border: none;

          svg {
            width: 12px;
            height: 12px;
          }
        }

        button:not(:last-child) {
          margin-right: 10px;
        }
      }

      .name{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: normal;

        p{
          font-size: 12px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
        }
      }
    }

    .table_item:nth-child(even) {
      background: $TABLE_BG_COLOR;
    }

    .table_item::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $BASE_GREEN_COLOR;
    }

    .table_item:hover {
      &::before {
        opacity: 0.5;
      }
    }
  }

  .pagination_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .ant-pagination-prev, .ant-pagination-next {
      button {
        border: 1px solid $BORDER_COLOR_PAGINATION;
        border-radius: 4px;
      }
    }
    .ant-pagination-item {
      border: 1px solid $BORDER_COLOR_PAGINATION;
      border-radius: 4px;
      a {
        color: $ICON_SEARCH_INPUT;
      }
    }
    .ant-pagination-item-active {
      border-width: 1px;
      border-color: $BORDER_COLOR_PAGINATION_ACTIVE;
      a {
        color: $BORDER_COLOR_PAGINATION_ACTIVE;
      }
    }
    .ant-pagination-options {
      height: 40px;
      .ant-select-arrow {
        margin-top: -2px;
      }
      .ant-select-selector {
        width: 140px;
        align-items: center;
        height: 40px;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
      }
    }
    .total {
      position: relative;
      left: 15%;
      color: $BASE_LIGHT_GRAY_COLOR;
      font-size: 15px;
    }
  }
}

.ant-tooltip-content {
  .ant-tooltip-arrow {
    &::before {
      background-color: $TOOLTIP_BG_COLOR;
    }
  }
  .ant-tooltip-inner {
    display: flex;
    align-items: center;
    background-color: $TOOLTIP_BG_COLOR;
    padding: 4px 13px;
  }
}
