.profile_statistic_layout {
  margin: 0px 43px;
  padding: 39px 0px 10px 0px;
  border-top: 1px solid $MAIN_BORDER_COLOR;
  border-bottom: 1px solid $MAIN_BORDER_COLOR;
  min-width: 1124px;
  background-color: $BASE_WHITE_COLOR;

  .statistic_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: flex-start;

    .card {
      max-width: 150px;
      display: flex;
      flex-direction: column;
      padding: 0px 16px;

      .quantity {
        font-weight: bold;
        font-size: 26px;
        line-height: 30px;
        color: $BASE_BLACK_COLOR;
        margin-bottom: 6px;
      }

      &:not(:last-child) {
        margin-right: 30px;
        border-right: 1px solid $MAIN_BORDER_COLOR;
      }
    }
  }
}
