.deliveryzone_basic_info_layout {
  input {
    height: 32px !important;
  }
  .deliveryzone_info_wrapper {
    display: flex;
    flex-direction: column;
    min-width: 1124px;
    border-top: solid 2px $SECONDARY_BG_COLOR;

    input {
      height: 32px;
      border-radius: 4px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      color: $BASE_BLACK_COLOR;
      font-size: 13px;
      border: none;
    }

    h3 {
      margin-top: 31px;
    }

    .content_wrapper {
      .ant-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .ant-form-item {
          flex: 1 1 25%;
          padding-right: 10px;
          margin-bottom: 35px;

          .has-error {
            .ant-input {
              background-color: $FORM_ELEMENT_BG_COLOR;
            }
            .ant-form-explain {
              margin-top: 5px;
            }
          }
        }

        .amount_per_order {
          min-width: 50%;
        }
        .minium-purchase-input {
          display: flex;
          align-items: center;
          input {
            flex: 1;
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
