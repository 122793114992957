@import "assets/styles/Global.scss";

@font-face {
  font-family: "Lato";
  src: url("../../assets/font/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "SpeedeeRegular";
  src: url("../../assets/font/Speedee/Speedee-Regular.ttf");
}

@font-face {
  font-family: "SpeedeeBold";
  src: url("../../assets/font/Speedee/Speedee-Bold.ttf");
}

body,
h3,
p,
span,
div,
a,
button,
select,
input {
  font-family: "Lato";
}

p {
  word-break: break-word;
  margin-top: 0px;
  margin-bottom: 0px;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: $BASE_BLACK_COLOR;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: $BASE_BLACK_COLOR;
  margin-bottom: 9px;
}

.title_deals {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: $BASE_BLACK_COLOR;
  margin-bottom: 14px;
}

.title_description {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: $BASE_GRAY_COLOR;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: $INPUT_MAIN_COLOR;
  margin-bottom: 16px;
}

.scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f6f6f6;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ececec;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.ant-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.ant-btn[disabled] {
  background-color: $DISABLED_BTN_COLOR !important;
}

.ant-btn {
  background: #333;
  transition: 0.6s;
  overflow: hidden;
  &:focus {
    outline: 0;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 60px;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 100%;
    left: 30px;
    top: 0;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }
  &:hover {
    cursor: pointer;
    &:before {
      transform: translateX(400px) skewX(-15deg);
      opacity: 0.6;
      transition: 0.7s;
    }
    &:after {
      transform: translateX(400px) skewX(-15deg);
      opacity: 1;
      transition: 0.7s;
    }
  }
}

.ant-select {
  .ant-select-selection {
    display: flex;
    align-items: center;
    min-height: 40px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    border-radius: 8px;
    border: none;
    padding: 8px 14px;
    font-size: 13px;
    font-weight: normal;
    line-height: 14px;

    .ant-select-selection__rendered {
      width: 100%;
    }

    .ant-select-arrow {
      position: unset;
      svg {
        width: 20px;
        color: $INPUT_MAIN_COLOR;
      }
    }
  }
}

.error_msg {
  font-size: 15px;
  color: $BASE_DARK_RED_COLOR;
}

.WP {
  background-color: $Waiting_Payment;
}
.RV {
  background-color: $Received;
}
.IP {
  background-color: $In_Production;
}
.AS {
  background-color: $Assigned;
}
.OW {
  background-color: $On_the_way;
}
.AD {
  background-color: $At_the_door;
}
.CL {
  background-color: $Claimed;
}
.RS {
  background-color: $Resolving;
}
.DD {
  background-color: $Delivered;
}
.CD {
  background-color: $Cancelled;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.just-center {
  justify-content: center;
}
.just-between {
  justify-content: space-between;
}
.just-around {
  justify-content: space-around;
}
.just-start {
  justify-content: flex-start;
}
.just-end {
  justify-content: flex-end;
}
.center {
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.full {
  width: 100%;
}
.bold {
  font-weight: bold;
}

.red_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ef4444;
  margin-left: 4px;
  vertical-align: middle;
}

.lang_btn {
  position: absolute;
  z-index: 100000;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $BASE_YELLOW_COLOR;
  color: $BASE_WHITE_COLOR;
  font-size: 12px;
  font-weight: bold;
  top: 10px;
  left: 10px;
  cursor: pointer;
  border-radius: 4px;
}
