.content_header_layout {
  display: flex;
  box-sizing: border-box;
  padding: 30px 45px 20px 40px;
  min-width: 1124px;
  font-family: 'Lato';
  font-size: 14px;
  color: $ICON_SEARCH_INPUT;
  .header_title {
    span {
      font-weight: normal;
      font-size: 19px;
      line-height: 22px;
      color: $BASE_BLACK_COLOR;
    }
    .header_description {
      p {
        margin-bottom: 1px;
      }
    }
  }
  .orders_header,
  .restaurants_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    color: $ICON_SEARCH_INPUT;

    .ant-input-affix-wrapper {
      width: 186px;
      height: fit-content;
      border-color: transparent;
      background-color: $MAIN_BG_COLOR;
      border-radius: 4px;

      input {
        border-radius: 4px;
        background-color: $MAIN_BG_COLOR;
        border: none;
        font-weight: normal;
        font-size: 13px;
        color: $INPUT_MAIN_COLOR;
        font-family: 'Lato';
      }

      .ant-input-suffix {
        svg {
          width: 15px;
          height: 15px;
          color: $INPUT_MAIN_COLOR;
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      border-radius: 4px;
      padding: 0 26px;

      span {
        color: $BASE_WHITE_COLOR;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
      }

      svg,
      img {
        width: 16px;
        height: 16px;
        margin-left: 12px;
        color: $BASE_WHITE_COLOR;
      }
    }
  }

  .orders_header {
    button {
      background-color: $BASE_BLACK_COLOR;
    }
  }

  .ots_agent_header_wrapper {
    width: 100%;
    a {
      text-decoration: none;
    }
    .managment_monitor_wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 28px;
      button {
        height: 62px;
        display: flex;
        align-items: center;
        background: $FORM_ELEMENT_BG_COLOR;
        border-radius: 4px;
        border: none;
        padding: 16px 14px;
        img {
          margin-right: 16px;
        }
        font-size: 13px;
        color: $BASE_DARK_BLACK_COLOR;
        font-family: 'Lato';
      }
    }
  }

  .restaurants_header,
  .deliveryzones_header,
  .restaurants_new_header,
  .delivery_header,
  .delivery_new_header,
  .configure_region_header,
  .catalogue_header,
  .catalogue_manage_header,
  .user_list_header,
  .user_edit_header,
  .ots_agent_list_header,
  .ots_agent_edit_header,
  .ots_agent_monitor_header,
  .ambassador_edit_header,
  .distribution_edit_header,
  .distribution_traffic_list_header,
  .distribution_report_header,
  .available_restaurant_header,
  .ambassador_monitor_header,
  .operator_agent_list_header,
  .operator_agent_edit_header,
  .promotion_list_header,
  .coupon_list_header,
  .deals_config_header_wrapper,
  .banner_setting_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .header_title {
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 19px;
      line-height: 22px;
      color: $BASE_BLACK_COLOR;
      cursor: pointer;
      a {
        .back_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 38px;
          height: 38px;
          background-color: $SECONDARY_BG_COLOR;
          border-radius: 50%;
          padding: 8px 8px;
          margin-right: 14px;
          svg {
            width: 22px;
            height: 22px;
            color: $BASE_LIGHT_BLACK_COLOR;
          }
        }
      }

      .user_info {
        display: flex;
        flex-direction: column;
        h3 {
          font-weight: normal;
          font-size: 19px;
          line-height: 22px;
          color: $BASE_BLACK_COLOR;
        }

        span {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $USER_EDIT_HEADER_TEXT_COLOR;
        }

        span:last-child {
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: #7B7B7B;
          margin-top: 4px;
        }
      }
      .deleted {
        display: inline-block;
        margin-left: 8px;
        text-align: center;
        background-color: #d2e6f0;
        padding: 0 8px;
        border-radius: 4px;
        font-size: 12px;
        color: $BASE_BLACK_COLOR;
      }
    }

    .btn_wrapper {
      display: flex;
      a {
        text-decoration: none;
      }
      button:hover {
        color: $BASE_WHITE_COLOR;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 169px;
        background-color: $BASE_YELLOW_COLOR;
        color: $BASE_WHITE_COLOR;
        border: none;
        border-radius: 4px;
        font-family: 'Lato';
        font-size: 13px;

        span,
        svg {
          color: $BASE_WHITE_COLOR;
          font-size: 13px;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .new_restaurant_btn,
      .new_delivery_btn {
        margin-right: 19px;
      }

      .delivery_zones_btn {
        min-width: unset;
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 18px;
        svg {
          margin-left: 0px;
        }
      }
    }

    .catalogue_btn_wrapper {
      display: flex;

      a {
        text-decoration: none;
      }

      button:hover {
        color: $BASE_DARK_BLACK_COLOR;
      }

      button {
        width: 245px;
        height: 62px;
        display: flex;
        align-items: center;
        padding: 16px 16px;
        border: none;
        background: $FORM_ELEMENT_BG_COLOR;
        border-radius: 4px;

        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_DARK_BLACK_COLOR;
        font-family: 'Lato';

        img {
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }

        &:not(:last-child) {
          margin-right: 21px;
        }
      }
    }
  }

  .ambassador_list_header,
  .distribution_list_header {
    width: 100%;
    .header_title {
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 19px;
      line-height: 22px;
      color: $BASE_BLACK_COLOR;
      justify-content: space-between;

      a {
        text-decoration: none;
      }

      .new_sector_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 169px;
        background-color: $BASE_YELLOW_COLOR;
        border: none;
        border-radius: 4px;
        color: $BASE_WHITE_COLOR;
        font-size: 13px;
        font-family: 'Lato';

        svg {
          color: $BASE_WHITE_COLOR;
          font-size: 13px;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .ambassador_header_btn_wrapper,
    .distribution_header_btn_wrapper {
      display: flex;
      margin-top: 28px;

      a {
        text-decoration: none;
      }
      button:hover {
        color: $BASE_DARK_BLACK_COLOR;
      }

      button {
        width: 245px;
        height: 62px;
        display: flex;
        align-items: center;
        background: $FORM_ELEMENT_BG_COLOR;
        border-radius: 4px;
        border: none;
        margin-right: 26px;

        img {
          margin-right: 16px;
        }
        font-size: 13px;
        color: $BASE_DARK_BLACK_COLOR;
        white-space: break-spaces;
        font-family: 'Lato';
      }
    }
    .distribution_header_btn_wrapper {
      button {
        width: 170px;
      }
    }
  }
  .ambassador_edit_header,
  .distribution_edit_header {
    border-radius: 4px;
    color: $BASE_WHITE_COLOR;
    font-size: 13px;
    font-family: 'Lato';
    .btn_wrapper {
      .ant-select-open {
        .ant-select-selection-item {
          color: black;
        }
      }
      .ambassador_status {
        height: 40px;
        margin-right: 13px;
        .ant-select-selector {
          height: 40px;
          min-width: 143px;
          align-items: center;
          border: none;
          background-color: $FORM_ELEMENT_BG_COLOR;
          font-weight: normal;
          font-size: 13px;
          border-radius: 8px;
        }
      }
      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      .delete_sector {
        background-color: $INPUT_MAIN_COLOR !important;
      }
    }
  }
  .distribution_traffic_list_header,
  .distribution_report_header {
    .header_title {
      align-items: flex-start;
      .header_description {
        .description {
          max-width: 600px;
          margin-bottom: 0px;
          font-size: 11px;
          color: $BASE_BLACK_COLOR;
        }
      }
    }
  }
  .restaurants_new_header {
    .delete_restaurant_btn {
      margin-right: 10px;
      background-color: $INPUT_MAIN_COLOR !important;
    }
  }
  .delivery_new_header {
    .delete_delivery_btn {
      margin-right: 10px;
      background-color: $INPUT_MAIN_COLOR !important;
    }
  }
  .sar_config_header_wrapper {
    width: 100%;
    height: 59px;
    border-bottom: 1px solid #edf0f2;
    font-weight: normal;
    font-size: 19px;
    line-height: 22px;
    color: $BASE_BLACK_COLOR;
  }
}
