.copy_button {
    display: flex;
    align-items: center;

    p {
      width: 109px;
      margin-right: 32px;
      font-size: 13px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 109px;
      background-color: #edf0f2;
      border: none;

      span,
      svg {
        color: $BASE_BLACK_COLOR;
        font-size: 13px;
        font-family: 'Lato';
        font-weight: normal;
        margin-right: 6px;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }