.delivery_settings_layout {
  display: flex;
  width: 100%;
  margin-top: 50px;

  .attend_schedule {
    width: 281px;
    margin-right: 31px;
    padding-right: 29px;
    position: relative;
  }
  .delivery_schedule {
    flex: 1;
  }
}
