.distribution_analytics_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .modal_header {
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .sector_status {
        width: fit-content;
        height: 21px;
        color: $BASE_WHITE_COLOR;
        border-radius: 25px;
        padding: 0 15px;
        margin-bottom: 12px;
        font-size: 10px;
      }
      .sector_H {
        background-color: $BASE_DARK_RED_COLOR;
      }
      .sector_M {
        background-color: $BASE_YELLOW_COLOR;
      }
      .sector_L {
        background-color: $BASE_GREEN_COLOR;
      }

      h3 {
        font-weight: bold;
        font-size: 17px;
        color: $BASE_BLACK_COLOR;
        margin-bottom: 10px;
      }
      .total_count {
        font-size: 12px;
        color: $BASE_BLACK_COLOR;
      }
    }

    .modal_content {
      display: block;
      .card {
        position: relative;
        height: 155px;
        background: $FORM_ELEMENT_BG_COLOR;
        border-radius: 4px;
        padding: 5px;
        .value {
          font-size: 50px;
          margin-left: 35px;
          margin-bottom: 0px;
          margin-top: 10px;
        }
        .label {
          font-size: 18px;
          margin-left: 35px;
          color: $BASE_BLACK_COLOR;
        }
      }
      .row1 {
        margin-top: 20px;
        .production-card {
          flex: 1;
          margin-right: 15px;
          .value {
            color: #f946b7;
          }
          &::before {
            content: '';
            position: absolute;
            width: 2.3px;
            height: calc(100% - 10px);
            top: 5px;
            left: 5px;
            background-color: #f946b7;
          }
        }
        .queue-card {
          flex: 1;
          margin-left: 15px;
          .value {
            color: #000000;
          }
          &::before {
            content: '';
            position: absolute;
            width: 2.3px;
            height: calc(100% - 10px);
            top: 5px;
            left: 5px;
            background-color: #000000;
          }
        }
      }
      .row2 {
        margin-top: 20px;
        .slope-card {
          flex: 1;
          margin-right: 15px;
          .value {
            color: #d35858;
          }
          &::before {
            content: '';
            position: absolute;
            width: 2.3px;
            height: calc(100% - 10px);
            top: 5px;
            left: 5px;
            background-color: #d35858;
          }
        }
        .service-card {
          flex: 1;
          margin-left: 15px;
        }
        .green-card {
          background: #eff4ea;
          .value {
            color: #8dd73c;
          }
          &::before {
            content: '';
            position: absolute;
            width: 2.3px;
            height: calc(100% - 10px);
            top: 5px;
            left: 5px;
            background: #8dd73c;
          }
        }
        .yellow-card {
          background: #f7efd8;
          .value {
            background: #f3b900;
          }
          &::before {
            content: '';
            position: absolute;
            width: 2.3px;
            height: calc(100% - 10px);
            top: 5px;
            left: 5px;
            background: #f3b900;
          }
        }
        .red-card {
          background: #fceded;
          .value {
            color: #f94646;
          }
          &::before {
            content: '';
            position: absolute;
            width: 2.3px;
            height: calc(100% - 10px);
            top: 5px;
            left: 5px;
            background-color: #f94646;
          }
        }
      }
    }

    .modal_footer {
      margin-top: 21px;
      .table-header {
        padding: 5px 0;
        .header {
          font-size: 12px;
          font-weight: bold;
          color: $INPUT_MAIN_COLOR;
        }

        .restaurant,
        .received,
        .on_route,
        .delivered,
        .solving {
          width: 16%;
        }
        .ambassadors_on_route {
          width: 20%;
        }
      }
      .table-content {
        .table-item {
          .restaurant,
          .received,
          .on_route,
          .delivered,
          .solving,
          .ambassadors_on_route {
            width: 16%;
            color: $ICON_SEARCH_INPUT;
          }
          .ambassadors_on_route {
            width: 20%;
          }
        }
      }
    }
  }
}
