.catalogue_manage_layout {
  display: flex;
  flex-direction: column;
  min-width: 1124px;

  .catalogue_scheme_description {
    display: flex;
    flex-direction: column;
    padding: 29px 39px 15px 39px;
    border-top: 1px solid $DIVIDER_BG_COLOR;
    border-bottom: 1px solid $DIVIDER_BG_COLOR;

    .description {
      line-height: 1.5;
    }

    button, .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      width: 180px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: $BASE_BLACK_COLOR;
      border-radius: 4px;
      color: $BASE_WHITE_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Lato';

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .catalogue_list {
    .smooth-dnd-container {
      width: 100%;
      padding: 30px 45px 20px 45px;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 2px 13px;
        width: calc(25% - 26px);
        background: $FORM_ELEMENT_BG_COLOR;
        border-radius: 8px;
        margin-right: 26px;
        margin-bottom: 16px;
        cursor: pointer;
        .catalogue_name {
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 14px;
          font-family: 'Lato';
          color: $BASE_DARK_GRAY_COLOR;
          img {
            height: 30px;
            margin-right: 14px;
          }
          p {
            margin-top: 10px;
          }
        }
        .channel {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 47px;
          height: 22px;
          border-radius: 4px;
          background-color: #dde2eb;
          font-weight: bold;
          font-size: 13px;
          color: $BASE_BLACK_COLOR;
        }
      }
    }
  }

  .catalogue_order_time_title {
    padding: 0px 45px;
    margin-top: 27px;
  }

  .catalogue_order_time_wrapper {
    padding: 0px 45px;
    margin-top: 30px;
    display: flex;
    min-height: 200px;

    .card {
      width: 25%;
      height: auto;
      padding: 16px 16px 0px 0px;
      pointer-events: auto;

      &:not(:last-child) {
        margin-right: 26px;
        border-right: 1px solid $DIVIDER_BG_COLOR;
      }

      .card_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin-bottom: 0px;
        }
      }

      .card_list {
        height: 100%;
        .smooth-dnd-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
          padding-top: 20px;
        }
        .item {
          width: 100%;
        }
        .item_wrapper {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .name_wrapper {
            display: flex;
            align-items: center;
            flex: 1;
            img {
              width: 24px;
              height: 24px;
              margin-right: 14px;
              cursor: pointer;
              pointer-events: none;
            }
            p {
              font-weight: bold;
              font-size: 13px;
              line-height: 14px;
              color: $BASE_BLACK_COLOR;
              padding-top: 10px;
            }
          }
          .channel_wrapper {
            display: flex;
            align-items: center;
            .channel {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
              height: 15px;
              border-radius: 4px;
              background-color: #dde2eb;
              font-weight: bold;
              font-size: 10px;
              color: $BASE_BLACK_COLOR;
              margin-right: 5px;
            }
            svg {
              display: flex;
              align-items: center;
              width: 18px;
              height: 18px;
              color: $ICON_SEARCH_INPUT;
            }
          }
        }
      }
    }
  }
}
