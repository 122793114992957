.promotion_new_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .ant-select-open {
      .ant-select-selection-item {
        color: black;
      }
    }

    input {
      height: 40px;
      border-radius: 4px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      color: $BASE_BLACK_COLOR;
      font-size: 13px;
      border: none;
    }

    .modal_header {
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .description {
        font-weight: normal;
        font-size: 11px;
        line-height: 23px;
        color: $INPUT_MAIN_COLOR;
        margin-bottom: 0px;
      }
    }

      .ant-select {
        height: 40px;
        .ant-select-selector {
          height: 40px;
          align-items: center;
          border: none;
          background-color: $FORM_ELEMENT_BG_COLOR;
          font-weight: normal;
          font-size: 13px;
          font-family: 'Lato';
          border-radius: 8px;
        }
        .ant-select-selection__rendered {
          max-width: 200px;

          .ant-select-search__field {
            height: 30px;
          }
        }
      }

    .modal_content {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      align-items: normal;
      margin-bottom: 0px;

      .date_picker_wrapper {
        display: flex;

        .ant-legacy-form-item {
          flex: 1;
          &:first-child {
            padding-right: 25px;
          }
          &:last-child {
            padding-left: 25px;
          }
          .ant-picker {
            width: 100%;
            height: 40px;
            background-color: $MAIN_BG_COLOR;
            border: none;
            border-radius: 4px;
            .ant-picker-input {
              .ant-picker-clear {
                svg {
                  background-color: $MAIN_BG_COLOR;
                }
              }
            }
          }
        }
      }

      .product_picker_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-form-item {
          flex: 1;
          padding-right: 26px;
        }

        .photo_area {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 54px;
          height: 54px;
          border-radius: 10px;
          img {
            height: 100%;
          }
        }
      }

      .photo_picker_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .photo_info {
          padding-right: 10px;
          button {
            background-color: $FORM_ELEMENT_BG_COLOR;
            border: none;
          }
        }
        .file_input {
          display: none;
        }
        .photo_area {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 165.48px;
          height: 118.29px;
          border-radius: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .promotion_order_wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 1px solid $SECONDARY_BG_COLOR;
        margin-top: 20px;
        padding-top: 16px;

        .ant-legacy-form-item {
          width: 100%;
        }
        .title {
          .subtitle {
            color: $BASE_LIGHT_GRAY_COLOR;
          }
        }
        .ant-select-selector {
          p {
            padding-top: 12px;
          }
        }
      }
    }

    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .aditional_detail {
        display: flex;
        align-items: center;
        .ant-legacy-form-item {
          margin-bottom: 0px;
        }
        .ant-switch-checked {
          background-color: $BASE_GREEN_COLOR;
        }

        p {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-right: 19px;
          margin-top: 10px;
        }
      }

      .btn_wrapper button {
        min-width: 0px;
      }
      .btn_wrapper {
        display: flex;
        align-items: center;

        .file_download_btn,
        .favorite_btn {
          width: 27px;
          height: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background-color: $TABLE_ACTION_BTN_BG_COLOR;
          border: none;

          svg {
            width: 12px;
            height: 12px;
          }
        }
        .file_download_btn {
          margin-right: 10px;
        }
        .favorite_btn {
          margin-right: 23px;
        }

        .close_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: none;
          font-size: 13px;

          svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
          }
        }

        .close_btn {
          height: 32px;
          background-color: $BASE_BLACK_COLOR;
          color: $BASE_WHITE_COLOR;
        }
      }
    }
  }
}
