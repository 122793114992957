.delivery_update_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 32px;

    input,
    textarea {
      border-radius: 4px;
      background-color: $MAIN_BG_COLOR;
      border: none;
      font-weight: normal;
      font-size: 13px;
      color: $INPUT_MAIN_COLOR;
    }

    .modal_header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
    }

    .modal_content {
      display: flex;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 35px;

      .label {
        font-weight: normal;
        font-size: 15px;
        line-height: 17px;
        color: $BASE_DARK_BLACK_COLOR;
      }

      .map_wrapper {
        width: 40%;
        height: 500px;
        padding-right: 35px;
      }

      .address_setting_wrapper {
        width: 60%;
        .address_direction {
          .address_detail {
            margin-top: 21px;
            margin-bottom: 13px;
          }
        }

        .custom_address {
          margin-top: 43px;

          .address {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 23px;

            .ant-form-item {
              width: 316px;
              margin-bottom: 0px;
            }
          }

          .restaurant {
            margin-top: 50px;
          }
        }

        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid $DIVIDER_BG_COLOR;
          padding-top: 16px;

          .aditional_detail {
            .geo_position {
              font-weight: normal;
              font-size: 13px;
              line-height: 14px;
              color: $USER_DELIVERY_UPDATE_FOODER_COLOR;
            }
          }

          .btn_wrapper {
            display: flex;
            align-items: center;

            .close_btn {
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-radius: 4px;
              border: none;
              font-size: 13px;
              background-color: $BASE_BLACK_COLOR;
              color: $BASE_WHITE_COLOR;
              svg {
                width: 16px;
                height: 16px;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
}
