.restaurant_schedule_layout {
  .restaurant_schedule {
    margin-top: 20px;
    padding-bottom: 30px;

    &:not(:last-child) {
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
    }

    .select_time_range {
      display: flex;

      .order_set {
        width: 40%;
        margin-right: 28px;
      }
      .open_time {
        width: 30%;
        margin-right: 28px;
      }
      .close_time {
        width: 30%;
      }
    }

    .time_slot_set {
      display: flex;
      align-items: center;
      margin-top: 32px;
      margin-bottom: 25px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        color: $BASE_BLACK_COLOR;
        margin-right: 10px;
      }

      p {
        margin-bottom: 0px;
      }
    }

    .slot_time_table {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 31px;

      .slot_time {
        display: flex;
        align-items: center;
        width: 25%;
        height: 50px;
        padding: 0 17px;
        margin-bottom: 8px;

        p {
          margin-right: 20px;
          margin-bottom: 0px;
        }

        .ant-dropdown-trigger {
          width: 73px;
          height: 32px;
        }
      }

      .divider {
        border-left: 1px solid $DIVIDER_BG_COLOR;
      }
    }
  }
}
