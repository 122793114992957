.location-search-input {
  position: absolute;
  z-index: 10;
  top: 27px;
  left: 45px;
  width: 241px;
  padding: 0;
  background-color: $FORM_ELEMENT_BG_COLOR;
  padding-left: 12px;

  input {
    width: 241px;
    height: 38px;
    border-radius: 11.5px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $MAIN_TEXT_COLOR;
    border: none;
  }

  svg {
    color: $MAP_ICON_COLOR;
  }
}

.autocomplete-dropdown-container {
  position: absolute;
  width: 239px;
  z-index: 10;
  top: 65px;
  left: 46px;
  background-color: $FORM_ELEMENT_BG_COLOR;
  div {
    padding: 10px 10px;
  }
}
