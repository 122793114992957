.sar_config_layout {
    .sar_config_wrapper {
      display: flex;
      flex-direction: column;
      min-width: 1124px;
      padding: 0px 21px;
    }
    .sar_form_layout {
      display: flex;
      flex-direction: column;
      min-width: 1124px;
      padding: 0px 21px;
      .sar_form_wrapper {
        display: flex;
        height: calc(100vh - 100px);
        .sub_title {
          font-size: 18px;
          color:#B5B5B5;
        }
        input {
          height: 32px;
          width: 474px;
          border-radius: 4px;
          background-color: $FORM_ELEMENT_BG_COLOR;
          color: $BASE_BLACK_COLOR;
          font-size: 13px;
          border: none;
        }
        .btn_wrapper {
          display: flex;
          align-items: center;
          .cancel_btn {
            margin-bottom: 0;
            color: $BASE_BLACK_COLOR;
            cursor: pointer;
            :hover {
              color: $BASE_DARK_BLACK_COLOR;
            }
          }
          button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 100px;
            background-color: $BASE_YELLOW_COLOR;
            border: none;
            border-radius: 4px;
            margin-right: 15px;
            color: $BASE_WHITE_COLOR;
            font-size: 13px;
            font-family: 'Lato';
    
            svg {
              color: $BASE_WHITE_COLOR;
              font-size: 13px;
            }
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }