.user_edit_layout {
  .user_profile_wrapper {
    display: flex;
    flex-direction: column;
    min-width: 1124px;
    margin: 0px 21px;
    border-top: 1px solid $MAIN_BORDER_COLOR;
    border-bottom: 1px solid $MAIN_BORDER_COLOR;

    input {
      height: 40px;
      border-radius: 4px;
      background-color: $FORM_ELEMENT_BG_COLOR;
      color: $BASE_BLACK_COLOR;
      font-size: 13px;
      border: none;
      font-family: "Lato";
    }

    .ant-input-password {
      border: 0;
      padding: 0;
      display: flex;
      position: relative;
      input {
        padding-left: 12px;
        padding-right: 32px;
        margin-top: 0 !important;
      }
      .ant-input-suffix {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        align-self: flex-end;
        position: absolute;
        right: 8px;
      }
    }

    .profile_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      h3 {
        font-weight: 700;
      }

      .user_state {
        display: flex;
        align-items: center;
        p {
          margin-right: 17px;
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          word-break: unset;
          margin-bottom: 0px;
        }

        .dropdown {
          width: 220px;
          height: 32px;

          &.isDisabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .ant-input {
          height: 32px;
          margin-left: 16px;
        }
      }
    }

    .error_wrapper {
      -webkit-box-shadow: 0px 10px 23px -6px rgba(0, 0, 0, 0.46);
      -moz-box-shadow: 0px 10px 23px -6px rgba(0, 0, 0, 0.46);
      box-shadow: 0px 10px 23px -6px rgba(0, 0, 0, 0.46);
      background-color: $BASE_WHITE_COLOR;
      border-radius: 0 0 12px 12px;
      padding-top: 2px;
      padding-bottom: 4px;
      position: absolute;
      width: 100%;
      opacity: 0;
      transform: translateY(-10px);
      animation: fadeIn 0.5s ease-in-out forwards;
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .error_message {
      color: red;
      margin-top: 4px;
      margin-left: 6px;
      margin-right: 4px;
    }

    .content_wrapper {
      margin-top: 43px;

      .ant-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 5px;

        .user_vip {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          .ant-switch-checked {
            background-color: $BASE_GREEN_COLOR;
          }
          .title_vip {
            margin-bottom: 0;
          }
        }
        .dropdown {
          height: 40px;

          &.isDisabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        .ant-form-item {
          flex: 1 1 25%;
          padding-right: 10px;
          margin-bottom: 35px;
          .title,
          .note {
            margin-bottom: 20px;
          }

          .has-error {
            .ant-input {
              background-color: $FORM_ELEMENT_BG_COLOR;
            }
            .ant-form-explain {
              margin-top: 5px;
            }
          }
        }

        .ant-picker {
          width: 100%;
          height: 40px;
          background-color: $MAIN_BG_COLOR;
          border: none;
          border-radius: 4px;
          input {
            background-color: $MAIN_BG_COLOR;
            border: none;
            border-radius: 4px;
          }
          .ant-picker-input {
            .ant-picker-clear {
              margin-right: 4px;
              svg {
                background-color: $MAIN_BG_COLOR;
              }
            }
          }

          svg {
            height: 15px;
          }
        }

        .phone_wrapper {
          position: relative;
          .react-tel-input {
            .flag-dropdown {
              border: none;
              height: 40px;
            }
            input {
              width: 100%;
              height: 40px;
              border-radius: 8px;
              background-color: $MAIN_BG_COLOR;
              border: none;
            }
          }
          .verify-icon {
            position: absolute;
            top: 12px;
            right: 8px;
            svg {
              width: 16px;
              height: 16px;
              color: #00c21f;
            }
          }
        }

        .password_label {
          display: flex;
          justify-content: space-between;
          .note {
            font-weight: normal;
            font-size: 10px;
            line-height: 11px;
            color: $USER_EDIT_PWD_LABEL_COLOR;
          }
        }
      }
    }
  }

  .user_order_wrapper {
    display: flex;
    .orders_wrapper {
      width: 60%;
      border-right: 1px solid $DIVIDER_BG_COLOR;
      .table_item {
        .bought {
          .code {
          }
          .note {
            margin-bottom: 0px;
          }
        }
        .date_time {
          .time,
          .note,
          .article {
            margin-bottom: 0px;
          }
        }
        .delivery_address {
          .address1,
          .address2,
          .product {
            margin-bottom: 0px;
          }
        }
      }
    }
    .delivery_wrapper {
      width: 40%;
    }
  }
}
