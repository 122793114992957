.catalogue_setup_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .modal_header {
      display: flex;
      justify-content: space-between;
    }

    .modal_content {
      display: block;
      margin-bottom: 0px;
      padding: 28px 0px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .icon_upload_wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 35px;

        p {
          width: 120px;
          margin-right: 32px;
          font-size: 13px;
          margin-bottom: 0px;
        }

        button {
          flex: 1;
          background-color: $FORM_ELEMENT_BG_COLOR;
          border: none;
          direction: rtl;
        }

        .file_input {
          display: none;
        }
      }

      .category_schedule {
        display: flex;
        justify-content: space-between;

        .schedule_from,
        .schedule_to {
          display: flex;
          align-items: center;

          p {
            margin-right: 16px;
            font-size: 13px;
          }
        }
      }

      .special-catalogue-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .setting {
          display: flex;
          .title {
            margin-right: 18px;
          }
          .ant-switch-checked {
            background-color: $BASE_GREEN_COLOR;
          }
        }
      }
    }

    .modal_footer {
      display: flex;
      justify-content: flex-end;

      button:hover {
        color: $BASE_WHITE_COLOR;
      }
      button {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        background-color: $BASE_BLACK_COLOR;
        border: none;
        color: $BASE_WHITE_COLOR;
        font-size: 13px;
        margin-top: 18px;

        svg {
          width: 16px;
          height: 16px;
          margin-left: 16px;
        }
      }
    }
  }
}
