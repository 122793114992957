.ots_agent_detail_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .modal_header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .userid {
        font-weight: bold;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
      }
    }

    .modal_content {
      display: flex;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 35px;

      .user_analytics {
        width: 100%;
        height: 120px;
        display: flex;

        div {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .quantity {
            font-weight: bold;
            font-size: 51px;
            line-height: 60px;
            color: $BASE_BLACK_COLOR;
          }
          .status_name {
            font-weight: bold;
            font-size: 11px;
            line-height: 12px;
            color: $BASE_BLACK_COLOR;
            margin-top: 7px;
          }

          &:not(:last-child) {
            border-right: 1px solid $DIVIDER_BG_COLOR;
          }
        }
      }
    }

    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .aditional_detail {
        .last_access_date {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 0px;
        }
        .ip_address {
          font-weight: bold;
          font-size: 11px;
          line-height: 12px;
          color: $CATEGORY_DETAIL_MODAL_SCHEDULE_TIME_COLOR;
          margin-top: 6px;
          margin-bottom: 0px;
        }
      }

      .btn_wrapper button {
        min-width: 0px;
      }
      .btn_wrapper {
        display: flex;
        align-items: center;

        .edit_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: $SECONDARY_BG_COLOR;
          border-radius: 4px;
          border: none;
          margin-right: 23px;

          svg {
            width: 16px;
            height: 16px;
            color: $BASE_BLACK_COLOR;
          }
        }

        .close_btn {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: none;
          font-size: 13px;
          background-color: $BASE_BLACK_COLOR;
          color: $BASE_WHITE_COLOR;
          svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
          }
        }
      }
    }
  }
}
