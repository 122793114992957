.polygon_map_layout {
  height: 690px;
  button {
    height: 32px;
    border-radius: 5px;
    margin-bottom: 10px;
    color: $BASE_WHITE_COLOR;
    border: none;
    cursor: pointer;
  }
  .load_btn {
    background-color: $BASE_YELLOW_COLOR;
    margin-right: 14px;
    font-size: 14px;
  }
  .delete_btn {
    background-color: $BASE_DARK_RED_COLOR;
    font-size: 14px;
  }
  .file_input {
    display: none;
  }
  .polygon_map_layout_upper {
    display: flex;
    justify-content: space-between;
    div:last-child {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-bottom: 16px;
      > span {
        display: flex;
        align-content: center;
        padding-right: 8px;
        margin-right: 8px;
        border-right: 1px solid black;
        font-size: 14px;
        color: $ICON_SEARCH_INPUT;
      }
    }
    .delete_polygon {
      white-space: break-spaces;
      text-align: end;
      align-items: center;
    }
    .show_other_zones {
      align-items: center;
    }
    span {
      font-size: 14px;
      color: $ICON_SEARCH_INPUT;
    }
  }
  .google_map_wrapper {
    height: 100%;
    position: relative;
    margin-bottom: 100px;
    .search_btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: $BASE_WHITE_COLOR;
      right: 60px;
      top: 10px;
      z-index: 100;
      i {
        color: $BASE_BLACK_COLOR;
      }
      svg {
        width: 18px;
        height: 18px;
        color: $BASE_BLACK_COLOR;
      }
    }
  }
}

.gm-style .gm-style-iw-c {
  background-color: $BASE_BLACK_COLOR;
  border-radius: 15px;
  p {
    color: $BASE_WHITE_COLOR;
    span {
      color: $USER_EDIT_HEADER_TEXT_COLOR;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.gm-style .gm-style-iw-d {
  padding: 0px 41px 18px 22px;
  overflow: unset !important;
}

.gm-style .gm-style-iw-t::after {
  background: $BASE_BLACK_COLOR;
}
