.traffic_monitor_table_layout {
  .ant-switch-checked {
    background-color: $BASE_GREEN_COLOR;
  }
  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }
  .ant-select {
    width: 100%;
    height: 40px;
    .ant-select-selector {
      height: 40px;
      align-items: center;
      border: none;
      background-color: $FORM_ELEMENT_BG_COLOR;
      font-weight: normal;
      font-size: 13px;
      font-family: 'Lato';
      border-radius: 8px;
      .ant-select-selection__rendered {
        margin: 0px;
      }
    }
  }
  .table_header {
    display: flex;
    padding: 12px 15px;
    color: $BASE_BLACK_COLOR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    .restaurant {
      width: 20%;
      display: flex;
      align-items: center;
    }

    .service,
    .geofence,
    .grouping,
    .maximum_orders {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .maximum_distance,
    .additional_service {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table_content {
    height: calc(100vh - 400px);
    overflow: auto;
    .table_item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border-top: 1px solid $MAIN_BORDER_COLOR;

      .title {
        font-size: 13px;
        line-height: 18px;
        color: $BASE_BLACK_COLOR;
        font-weight: normal;
        margin-bottom: 0px;
      }
      .subtitle {
        font-size: 11px;
        line-height: 12px;
        font-weight: normal;
        color: $BASE_LIGHT_GRAY_COLOR;
      }

      .restaurant {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .address2 {
          color: $BASE_LIGHT_GRAY_COLOR;
        }
      }

      .ant-switch-checked:hover {
        background-color: #7ed321;
      }
      .ant-switch:hover:not(.ant-switch-checked) {
        background: rgba(0, 0, 0, 0.25);
      }

      .service,
      .geofence,
      .grouping,
      .maximum_orders {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .maximum_distance,
      .additional_service {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .pagination_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .ant-pagination-prev, .ant-pagination-next {
      button {
        border: 1px solid $BORDER_COLOR_PAGINATION;
        border-radius: 4px;
      }
    }
    .ant-pagination-item {
      border: 1px solid $BORDER_COLOR_PAGINATION;
      border-radius: 4px;
      a {
        color: $ICON_SEARCH_INPUT;
      }
    }
    .ant-pagination-item-active {
      border-width: 1px;
      border-color: $BORDER_COLOR_PAGINATION_ACTIVE;
      a {
        color: $BORDER_COLOR_PAGINATION_ACTIVE;
      }
    }
    .ant-pagination-options {
      height: 40px;
      .ant-select-arrow {
        margin-top: -2px;
      }
      .ant-select-selector {
        width: 140px;
        align-items: center;
        height: 40px;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
      }
    }
  }
}
