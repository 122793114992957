.code_view_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 0px 35px;

    .modal_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;
      margin-bottom: 20px;

      .name_wrapper {
        h3 {
          margin-bottom: 0px;
        }
      }
    }
  }
  .close_modal {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: $BASE_BLACK_COLOR;
    :hover {
      color: $BASE_LIGHT_GRAY_COLOR;
    }
  }
}

.name_wrapper {
  display: flex;
  align-items: center;
}

.name_wrapper h3 {
  margin-right: 20px;
}

.modal_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.code_subtitle {
  color: #91929c;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.modal_content_text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #edf0f2;
  padding-right: 40px;
  max-width: 50%;
}

.modal_text_code {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 36px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
  }
}

.modal_content_text h2 {
  font-size: 36px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.modal_content_text p {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.modal_content_qrcode {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
}

.modal_content_qrcode span {
  color: #000;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: -10px;
}

.modal_content_qrcode p {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
}

.open_offer {
  display: flex;
  justify-content: right;
  padding-top: 20px;
  padding-bottom: 19px;
  border-top: 1px solid #edf0f2;

  p {
    margin-bottom: 0px;
  }

  button {
    width: 46px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: $TABLE_ACTION_BTN_BG_COLOR;
    border: none;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  button:not(:last-child) {
    margin-right: 10px;
  }
}
