.order_analysis_wrapper {
  display: flex;
  width: 100%;
  padding: 20px 45px;
  .statistic_wrapper {
    width: 40%;
    .title {
      font-size: 12px;
      font-weight: normal;
      color: #212935;
    }
    .card_item {
      position: relative;
      padding-top: 15px;
      padding-bottom: 5px;
      padding-left: 5px;
      background: $FORM_ELEMENT_BG_COLOR;
      border-radius: 4px;
      margin-top: 14px;
      cursor: pointer;

      &::before {
        content: ' ';
        position: absolute;
        width: 2px;
        height: calc(100% - 10px);
        background: #687992;
        left: 5px;
        top: 5px;
      }
      .count_number {
        font-size: 50px;
        color: #687992;
        margin-left: 27px;
        margin-bottom: 10px;
      }
      .card_description_wrapper {
        margin-left: 27px;
        .card_title {
          font-size: 18px;
          color: black;
          margin-bottom: 10px;
        }
        .card_description {
          font-size: 12px;
          color: #212935;
          margin-top: 6px;
        }
      }
    }

    .received_card {
      display: flex;
      align-items: center;
    }

    .card_row {
      display: flex;
      align-items: center;
      .card_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 188px;
      }
      .route_card {
        flex: 1;
        margin-right: 12px;
        &::before {
          background: #f946b7;
        }
        .count_number {
          color: #f946b7;
        }
      }
      .production_card {
        flex: 1;
        margin-left: 12px;
        &::before {
          background: #50e2c2;
        }
        .count_number {
          color: #50e2c2;
        }
      }
      .solving_card {
        flex: 1;
        margin-right: 12px;
        background: #fceded;

        &::before {
          background: #d35858;
        }
        .count_number {
          color: #d35858;
        }
      }
      .sloping_card {
        flex: 1;
        margin-left: 12px;
        &::before {
          background: #f3b900;
        }
        .count_number {
          color: #f3b900;
        }
      }
      .delivered_card {
        flex: 1;
        margin-right: 12px;
        background: #eff4ea;

        &::before {
          background: #8dd73c;
        }
        .count_number {
          color: #8dd73c;
        }
      }
      .ambassadors_card {
        flex: 1;
        margin-left: 12px;
      }
    }
  }
  .analysis_chart_wrapper {
    position: relative;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
}
