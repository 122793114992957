.offers_wrapper {
    margin-top: 26px;
    .btn_wrapper {
        display: flex;
        margin-top: 15px;
        justify-content: flex-start;
        margin-bottom: 15px;
    
        button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 169px;
            background-color: $BASE_YELLOW_COLOR;
            border: none;
    
            span,
            svg {
                color: $BASE_WHITE_COLOR;
                font-size: 13px;
            }
        }

        .right_btns {
            margin-left: auto;
            display: flex;
            gap: 10px;
            a {
                text-decoration: none;
            }
          }
    }
}
