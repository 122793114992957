.orders_table_layout {
  .table_header {
    display: flex;
    padding: 12px 15px;
    color: $BASE_BLACK_COLOR;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    .order,
    .delivery_address,
    .date_time {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .total,
    .bought,
    .qualification,
    .actions {
      width: 10%;
      display: flex;
      align-items: center;
    }

    .order {
      svg {
        height: 15px;
        margin-right: 22px;
        margin-top: -2px;
      }
    }

    .actions {
      justify-content: center;
    }
  }

  .table_content {
    height: calc(100vh - 300px);
    overflow: auto;
    .table_item {
      cursor: pointer;
      position: relative;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border-top: 1px solid $MAIN_BORDER_COLOR;
      .status_line {
        position: absolute;
        left: 0;
        width: 4px;
        height: 100%;
      }
      .order,
      .delivery_address,
      .date_time {
        width: 20%;
        display: flex;
        flex-direction: column;
      }
      .total,
      .qualification,
      .bought {
        width: 10%;
        display: flex;
        flex-direction: column;
      }
      .qualification {
        .ant-rate {
          color: $BASE_BLACK_COLOR;
        }
      }
      .actions {
        width: 10%;
        display: flex;
        justify-content: center;

        button {
          width: 27px;
          height: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background-color: $TABLE_ACTION_BTN_BG_COLOR;
          border: none;

          svg {
            width: 12px;
            height: 12px;
          }
        }
        button:not(:last-child) {
          margin-right: 10px;
        }
      }
      .order {
        padding-left: 44px;
        .code {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 8px;
        }
        .reference_id {
          color: $BASE_LIGHT_GRAY_COLOR;
          margin-bottom: 0px;
        }
        .address {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 8px;
        }
        .phone,
        .email {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 12px;
          color: $BASE_LIGHT_GRAY_COLOR;
          margin-bottom: 0px;
        }
        .phone {
          margin-bottom: 8px;
        }
      }

      .bought {
        .article {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 8px;
        }
        .device {
          width: 58px;
          border-radius: 4px;
          padding: 4px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          color: $BASE_BLACK_COLOR;
          background-color: $TABLE_DEVICE_BG_COLOR;
        }
      }

      .delivery_address {
        .address1 {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 0px;
        }
        .address2 {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
          margin-bottom: 0px;
        }
        .product {
          width: 120px;
          border-radius: 4px;
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          color: $BASE_BLACK_COLOR;
          background-color: $TABLE_DEVICE_BG_COLOR;
          text-align: center;
          margin-bottom: 0px;
        }
      }

      .date_time {
        .time {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 0px;
        }
        .note {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
          margin-bottom: 0px;
        }
      }

      .total {
        .amount {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 0px;
        }
        .note {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: $BASE_LIGHT_GRAY_COLOR;
          margin-bottom: 0px;
        }
        .message {
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 12px;
          color: $BASE_LIGHT_RED_COLOR;
          margin-bottom: 0px;
        }
      }
    }

    .table_item:nth-child(even) {
      background: $TABLE_BG_COLOR;
    }
    .table_item:hover {
      background-color: $HOVER_COLOR;
    }
    .order_WP {
      background-color: $Waiting_Payment;
    }
    .order_RV {
      background-color: $Received;
    }
    .order_IP {
      background-color: $In_Production;
    }
    .order_AS {
      background-color: $Assigned;
    }
    .order_OW {
      background-color: $On_the_way;
    }
    .order_AD {
      background-color: $At_the_door;
    }
    .order_CL {
      background-color: $Claimed;
    }
    .order_RS {
      background-color: $Resolving;
    }
    .order_DD {
      background-color: $Delivered;
    }
    .order_CD {
      background-color: $Cancelled;
    }
  }

  .pagination_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .ant-pagination-prev, .ant-pagination-next {
      button {
        border: 1px solid $BORDER_COLOR_PAGINATION;
        border-radius: 4px;
      }
    }
    .ant-pagination-item {
      border: 1px solid $BORDER_COLOR_PAGINATION;
      border-radius: 4px;
      a {
        color: $ICON_SEARCH_INPUT;
      }
    }
    .ant-pagination-item-active {
      border-width: 1px;
      border-color: $BORDER_COLOR_PAGINATION_ACTIVE;
      a {
        color: $BORDER_COLOR_PAGINATION_ACTIVE;
      }
    }
    .ant-pagination-options {
      height: 40px;
      .ant-select-arrow {
        margin-top: -2px;
      }
      .ant-select-selector {
        width: 140px;
        align-items: center;
        height: 40px;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
      }
    }
    .total {
      position: relative;
      left: 15%;
      color: $BASE_LIGHT_GRAY_COLOR;
      font-size: 15px;
    }
  }
}

.ant-tooltip-content {
  .ant-tooltip-arrow {
    &::before {
      background-color: $BASE_BLACK_COLOR !important;
    }
  }
  .ant-tooltip-inner {
    display: flex;
    align-items: center;
    background-color: $BASE_BLACK_COLOR !important;
  }
  .feedback_tooltip {
    width: 100%;
    padding: 10px;

    .food_feedback,
    .service_feedback,
    .time_feedback {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // .ant-rate {
      //     color: $BASE_BLACK_COLOR;
      // }
    }
  }
}
