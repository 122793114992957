.loyalty_layout {
  height: 100%;

  .ant-select-open {
    .ant-select-selection-item {
      color: black;
    }
  }

  .title {
    font-size: 18px;
    color: #b5b5b5;
    margin-bottom: 35px;
  }

  .label {
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    margin-bottom: 14px;
  }

  input {
    height: 40px;
    border-radius: 4px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    color: $BASE_BLACK_COLOR;
    font-size: 13px;
    border: none;
  }

  .ant-switch-checked {
    background-color: $BASE_GREEN_COLOR;
  }
  .ant-switch.ant-switch-checked:hover {
    background-color: #7ed321;
  }
  .ant-switch:hover:not(.ant-switch-checked) {
    background: rgba(0, 0, 0, 0.25);
  }

  .ant-picker {
    height: 40px;
    background-color: $MAIN_BG_COLOR;
    border: none;
    border-radius: 4px;

    svg {
      height: 15px;
      fill: #000000;
    }
  }

  .form-wrapper {
    width: 100%;
    display: flex;
    .left-wrapper {
      width: calc(50% - 15px);
      margin-right: 15px;
      .ant-select-selector {
        height: 40px;
        align-items: center;
        border: none;
        background-color: $FORM_ELEMENT_BG_COLOR;
        font-weight: normal;
        font-size: 13px;
        font-family: 'Lato';
        border-radius: 8px;
      }
    }
    .right-wrapper {
      width: calc(50% - 15px);
      margin-left: 15px;
      .ant-select {
        display: block;
        .ant-select-selector {
          min-height: 40px;
          padding: 5px;
          background-color: $FORM_ELEMENT_BG_COLOR;
          font-weight: normal;
          font-size: 13px;
          font-family: 'Lato';
          border-radius: 8px;
          border: none;
          .ant-select-selection-item {
            padding-left: 5px;
            p {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
    .start-date-time {
      display: flex;
      .start_date {
        flex: 1;
        margin-right: 4px;
        .ant-picker-clear {
          margin-right: 4px;
          svg {
            background-color: $MAIN_BG_COLOR;
          }
        }
      }
      .start_time {
        flex: 1;
        margin-left: 4px;
      }
    }
    .end-date-time {
      display: flex;
      .end_date {
        flex: 1;
        margin-right: 4px;
        .ant-picker-clear {
          margin-right: 4px;
          svg {
            background-color: $MAIN_BG_COLOR;
          }
        }
      }
      .end_time {
        flex: 1;
        margin-left: 4px;
      }
    }
  }

  .ant-legacy-form {
    padding: 0 64px 0 24px;

    .challenge-setting-wrapper {
      margin-top: 40px;
      padding: 0 100px 40px 26px;
      border-bottom: 1px solid $MAIN_BORDER_COLOR;

      input, input:hover {
        background-color: #f4f5f7;
      }

      .challenge_toggle-wrapper {
        .ant-legacy-form-item-control-wrapper {
          width: 100%;
          .ant-legacy-form-item-children {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .label {
              margin-right: 15px;
              margin-top: 15px;
            }
          }
        }
      }
    }

    .award-setting-wrapper {
      margin-top: 30px;
      padding: 0 100px 40px 26px;
      border-bottom: 1px solid $MAIN_BORDER_COLOR;
    }

    .additional-configuration-wrapper {
      margin-top: 30px;
      padding: 0 100px 43px 26px;
      input, input:hover {
        background-color: #f4f5f7;
      }
    }
  }
}

.ant-select-item-option-content {
  p {
    margin-bottom: 0px;
  }
}
