.catalogue_view_modal {
  .ant-modal-content {
    background: $BASE_WHITE_COLOR;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
    padding: 42px 34px 29px 35px;

    .modal_header {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 16px;
      border-bottom: 1px solid $DIVIDER_BG_COLOR;

      .title_wrapper, .info_wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

          h3 {
            margin-bottom: 0;
          }
      }

      .label_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
      }

      .last_import_time {
        font-weight: normal;
        font-size: 11px;
        line-height: 23px;
        color: $INPUT_MAIN_COLOR;
        margin-bottom: 0px;
      }

      .promo {
        text-align: center;
        background-color: $BASE_YELLOW_COLOR;
        padding: 0 8px;
        border-radius: 4px;
        font-size: 10px;
      }

      .hidden {
        text-align: center;
        background-color: #d2e6f0;
        padding: 0 8px;
        border-radius: 4px;
        font-size: 10px;
      }

      .sku {
        font-weight: bold;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_BLACK_COLOR;
        margin-bottom: 0;
      }
    }

    .modal_content {
      display: flex;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 35px;

      .food_photo {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 138px;
        max-width: 200px;
        height: auto;
        margin-right: 26px;
        background-color: $SECONDARY_BG_COLOR;

        img {
          width: 100%;
        }
      }

      .detail {
        .description {
          font-weight: normal;
          font-size: 14px;
          line-height: 23px;
          color: $BASE_DARK_BLACK_COLOR;
        }
        .base_price {
          font-weight: normal;
          font-size: 16px;
          line-height: 23px;
          color: $BASE_BLACK_COLOR;
          margin-top: 16px;
          margin-bottom: 0px;
        }
      }
    }

    .modal_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $DIVIDER_BG_COLOR;
      padding-top: 16px;

      .aditional_detail {
        .category_name {
          font-weight: bold;
          font-size: 13px;
          line-height: 14px;
          color: $BASE_BLACK_COLOR;
          margin-bottom: 0px;
        }
        .schedule_time {
          font-weight: bold;
          font-size: 11px;
          line-height: 12px;
          color: $CATEGORY_DETAIL_MODAL_SCHEDULE_TIME_COLOR;
          margin-top: 6px;
          margin-bottom: 0px;
        }
      }

      .btn_wrapper button {
        min-width: 0px;
      }
      .btn_wrapper {
        display: flex;
        align-items: center;

        .file_download_btn,
        .favorite_btn {
          width: 27px;
          height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background-color: $TABLE_ACTION_BTN_BG_COLOR;
          border: none;

          svg {
            width: 12px;
            height: 12px;
          }
        }
        .file_download_btn {
          margin-right: 10px;
        }
        .favorite_btn {
          margin-right: 23px;
        }

        .close_btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          border: none;
          font-size: 13px;

          svg {
            width: 16px;
            height: 16px;
            margin-left: 16px;
          }
        }

        .close_btn {
          height: 32px;
          background-color: $BASE_BLACK_COLOR;
          color: $BASE_WHITE_COLOR;
        }
      }
    }
  }
}
